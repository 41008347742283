import React from "react"
import "../../css/booking.css"
import { useParams, useNavigate } from "react-router-dom"
import { useOneBookingDetails } from "common/Hooks/queryCalls"
import { LottieLoading } from "common/LottieLoading"
import ListGroup from "react-bootstrap/ListGroup"
import {
  format24ToTime,
  formatCurrency,
  formatDate,
  formatDateOnly,
} from "common/Services/functions"
import { LoadingButton } from "@mui/lab"
import { Button } from "@mui/material"
import SaveIcon from "@mui/icons-material/Save"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { acceptBooking, BookingStatusUpdate } from "common/Services/dbServices"
import dayjs from "dayjs"
const BookingDetails = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false)
  const { id } = useParams()
  const { data, isLoading, refetch } = useOneBookingDetails({ id })
  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }

  console.log("daataaaatataatat", data)

  const handleBookingStatusUpdate = async ({ itemId, orderStatus }) => {
    setLoading(true)
    try {
      const result = await BookingStatusUpdate({
        itemId,
        orderStatus,
        endTime: dayjs().format("HH:mm"),
      })
      refetch()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      alert(error)
    }
  }

  const handleAcceptBooking = async () => {
    setLoading(true)
    try {
      const result = await acceptBooking({ id })
      refetch()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      alert(error)
    }
  }
  return (
    <>
      <div className="booking-details" style={{ marginTop: "20px" }}>
        <div className="order_update_btn_div">
          <Button
            sx={{
              "@media (max-width: 600px)": {
                fontSize: "0.6rem",
                height: 35,
                backgroundColor: "#333547",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                fontSize: "0.8rem",
                height: 35,
                backgroundColor: "#333547",
              },
              "@media (min-width: 970px)": {
                fontSize: "0.8rem",
                height: 35,
                backgroundColor: "#333547",
              },
            }}
            style={{ fontFamily: "outfit", margin: "8px" }}
            variant="contained"
            onClick={() => navigate(-1 || `/bookings`)}
          >
            Back
          </Button>

          {data?.status == "pending" ? (
            loading ? (
              <LoadingButton
                size="small"
                color="secondary"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 130,
                    height: 35,
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                  },
                }}
                style={{ fontFamily: "outfit" }}
                variant="contained"
              >
                <span> Accept Order</span>
              </LoadingButton>
            ) : (
              <Button
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 130,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                }}
                style={{ fontFamily: "outfit" }}
                variant="contained"
                onClick={() => handleAcceptBooking()}
              >
                Accept Order
              </Button>
            )
          ) : (
            ""
          )}

          {data?.status == "accepted" ? (
            loading ? (
              <LoadingButton
                size="small"
                color="secondary"
                loading={loading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 150,
                    height: 35,
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                  },
                }}
                style={{ fontFamily: "outfit" }}
              >
                <span> Mark As Completed</span>
              </LoadingButton>
            ) : (
              <Button
                sx={{
                  "@media (max-width: 600px)": {
                    fontSize: "0.6rem",
                    width: 150,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 601px) and (max-width: 960px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                  "@media (min-width: 970px)": {
                    fontSize: "0.8rem",
                    width: 200,
                    height: 35,
                    backgroundColor: "#333547",
                  },
                }}
                style={{ fontFamily: "outfit" }}
                variant="contained"
                onClick={() =>
                  handleBookingStatusUpdate({
                    itemId: id,
                    orderStatus: "completed",
                  })
                }
              >
                Mark As Completed
              </Button>
            )
          ) : (
            ""
          )}
        </div>
        <ListGroup>
          <ListGroup.Item>
            <div
              className="booking-detail-row"
              style={{ fontFamily: "outfit" }}
            >
              <div className="booking-detail-element">
                <h6>Reference:</h6>
                <h6>{data?.refNumber}</h6>
              </div>
              <div className="booking-detail-element">
                <h6>Placed On:</h6>
                <h6>{formatDate(data?.placedOn)}</h6>
              </div>
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <div
              className="booking-detail-row"
              style={{ fontFamily: "outfit" }}
            >
              <div className="booking-detail-element">
                <h6>Booking Date:</h6>
                <h6>{formatDateOnly(data?.bookedDate)}</h6>
              </div>{" "}
              <div className="booking-detail-element">
                <h6>Assigned To:</h6>
                <h6>
                  {data?.serviceMemberData?.length > 0 &&
                  data?.serviceMemberData[0]
                    ? data?.serviceMemberData[0]?.name
                    : `-`}
                </h6>
              </div>
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <div
              className="booking-detail-row"
              style={{ fontFamily: "outfit" }}
            >
              <div className="booking-detail-element">
                <h6>Customer Name:</h6>
                <h6>{`${data?.customerData?.firstName} ${data?.customerData?.lastName}`}</h6>
              </div>{" "}
              <div className="booking-detail-element">
                <h6>Customer Number:</h6>
                <h6>
                  {data?.customerData && data?.customerData?.number
                    ? data?.customerData?.number
                    : `-`}
                </h6>
              </div>
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <div
              className="booking-detail-row"
              style={{ fontFamily: "outfit" }}
            >
              <div className="booking-detail-element">
                <h6>Start Time:</h6>
                <h6>{format24ToTime(data?.startTime)}</h6>
              </div>{" "}
              {data?.endTime ? (
                <div className="booking-detail-element">
                  <h6>End Time:</h6>
                  <h6>{format24ToTime(data?.endTime)}</h6>
                </div>
              ) : (
                <div className="booking-detail-element">
                  <h6>Estimated End Time:</h6>
                  <h6>{format24ToTime(data?.estimatedEndTime)}</h6>
                </div>
              )}
            </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div
              className="booking-detail-row"
              style={{ fontFamily: "outfit" }}
            >
              <div className="booking-detail-element">
                <h6>Booking Status:</h6>
                <h6>{data?.status}</h6>
              </div>{" "}
              <div className="booking-detail-element">
                <h6>Payment Status:</h6>
                <h6>{data?.paymentStatus}</h6>
              </div>
            </div>
          </ListGroup.Item>

          <ListGroup.Item>
            <div
              className="booking-detail-row"
              style={{ fontFamily: "outfit" }}
            >
              <div className="booking-detail-element">
                <h6>Item Total Amount:</h6>
                <h6>{formatCurrency(data?.payments?.itemTotal || 0)}</h6>
              </div>
              <div className="booking-detail-element">
                <h6>Total Amount Payable:</h6>
                <h6>
                  {formatCurrency(
                    data?.amount || data?.payments?.amountPayable || 0
                  )}
                </h6>
              </div>
            </div>
          </ListGroup.Item>
          {data?.completedOn ? (
            <ListGroup.Item>
              <div
                className="booking-detail-row"
                style={{ fontFamily: "outfit" }}
              >
                <div className="booking-detail-element">
                  <h6>Completed On:</h6>
                  <h6>{formatDate(data?.completedOn)}</h6>
                </div>
              </div>
            </ListGroup.Item>
          ) : (
            ""
          )}
        </ListGroup>
        <ListGroup>
          <ListGroup.Item style={{ padding: 0 }}>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={tableHeadStyle}
                      align="left"
                      style={{ fontFamily: "outfit" }}
                    >
                      Services
                    </TableCell>
                    <TableCell
                      sx={tableHeadStyle}
                      align="center"
                      style={{ fontFamily: "outfit" }}
                    >
                      Service Category
                    </TableCell>
                    <TableCell
                      sx={tableHeadStyle}
                      align="center"
                      style={{ fontFamily: "outfit" }}
                    >
                      Duration
                    </TableCell>
                    <TableCell
                      sx={tableHeadStyle}
                      align="center"
                      style={{ fontFamily: "outfit" }}
                    >
                      Image
                    </TableCell>
                    <TableCell
                      sx={tableHeadStyle}
                      align="center"
                      style={{ fontFamily: "outfit" }}
                    >
                      Price
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.items?.map(row => (
                    <TableRow key={row?._id}>
                      <TableCell
                        component="th"
                        scope="row"
                        align="left"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {`${row?.name?.en}`}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                          }}
                        >
                          {row?.getServiceCategory?.map((option, index) => (
                            <p className="order-variant-p" key={index}>
                              {option?.name?.en}
                            </p>
                          ))}
                        </div>
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {`${row?.duration?.HH} hr : ${row?.duration?.MM} min `}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        <img
                          src={row?.image?.url}
                          style={{ width: "60px", height: "60px" }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        align="center"
                        sx={tableBodyStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        {`${formatCurrency(row?.salePrice || row?.price)}`}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ListGroup.Item>{" "}
        </ListGroup>
      </div>
    </>
  )
}

export default BookingDetails
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}
