import { useQuery } from "react-query"
import { useSelector } from "react-redux"
import {
  getCurrentProvider,
  getProvider,
  getBranches,
  getReviews,
  getBranchModifiers,
  getBranchProducts,
  getModifiers,
  getAllCategoryCount,
  getSubCategories,
  getSubCategoriesCount,
  getProducts,
  getProductsCount,
  getCategorySearch,
  getCategorySetting,
  getBranchCity,
  getPrefixCheck,
  getBranchCitySetting,
  getProductCategorySearch,
  getCompletedOrders,
  getOngoingOrders,
  getSpecificSubcatogories,
  getAddons,
  getOneOrderDetail,
  getProductsModifiers,
  getBranchesSearch,
  getOrdersThisyear,
  getOrdersThisMonth,
  getOrdersBtwDates,
  getBookingsBtwDates,
  getOrdersThisDay,
  getOrdersThisWeek,
  getSpecificSubcatogoriesSetting,
  getAllCategory,
  getFetchAllCategory,
  getAudience,
  getChatList,
  getCustomerOrders,
  getCustomerBookings,
  getCustomerDetails,
  getInCompleteOrders,
  getLastOrder,
  getAddress,
  getFilteringProducts,
  getOneMenu,
  getProductVariants,
  getOneProductVariants,
  getCoupons,
  getRevenue,
  getAudienceCount,
  getBranchProductsCount,
  getAudienceOrder,
  getServiceCategories,
  getOneServiceCategory,
  getServiceCategorySetting,
  getServiceCategorySearch,
  getServices,
  getOneService,
  getTeamMembers,
  getOneTeamMember,
  getServiceCategoryTeamMembers,
  getServicesTeamMembers,
  getBookings,
  getOneBookingDetails,
  getLastBooking,
  getBookingsThisyear,
  getBookingsThisMonth,
  getBookingsThisWeek,
  getBookingsThisDay,
  getBookingServices,
  getAudienceBookings,
  getServiceMembers,
  getCouponProducts,
  getCouponCustomer,
  getCms,
  getOneCms
} from "../Services/dbServices"

import { UserContext } from "Context/userContext"
import { useContext } from "react"

export const useSubCategories = ({
  providerId,
  options = { sort: { _id: -1 }, skip: 0, limit: 10 },
}) => {
  // const authUser = useSelector((x) => x?.auth?.user);
  const { user } = useContext(UserContext)
  // console.log('user',user)
  return useQuery(["subCategories", user, { providerId }, options], () =>
    getSubCategories({ providerId, options })
  )
}

export const useSubCategoriesCount = ({ providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["subCategoryCount", user, { providerId }], () =>
    getSubCategoriesCount({ providerId })
  )
}
export const useCurrentProvider = () => {
  const { user } = useContext(UserContext)
  // console.log("emailpasswordlogin", user?.customData)
  // console.log("customdata::",customerData)
  // const authUser = useSelector((x) => x?.auth?.user);
  // const customDataUser = useSelector((x) => x?.auth?.customData);
  //  console.log("authUser:", customerData);
  // console.log("customDataUser:", customDataUser);
  // console.log("customDataUser entityId:", customDataUser?.entityId);
  return useQuery(
    [
      "currentProvider",
      user,
      user?.customData,
      { customerId: user?.customData?.entityId || null },
    ],
    () => getCurrentProvider({ providerId: user?.customData?.entityId || null })
  )
}

export const useProvider = ({ providerId }) => {
  // const authUser = useSelector((x) => x.auth.user);
  const { user } = useContext(UserContext)
  return useQuery(["providerDetails", user, { providerId }], () =>
    getProvider({ providerId })
  )
}

// export const useBranches = ({ providerId }) => {
//   const authUser = useSelector((x) => x.auth.user);
//   return useQuery(["branches", authUser, { providerId }], () =>
//     getBranches({ providerId })
//   );
// };

export const useBranchModifiers = ({ branchId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["branchModifiers", user, { branchId }], () =>
    getBranchModifiers({ branchId })
  )
}

export const useBranchProducts = ({ branchId, status, stock, options }) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["branchProducts", user, { branchId, status, stock }, options],
    () => getBranchProducts({ branchId, status, stock, options })
  )
}

export const useBranchProductsCount = ({ branchId, status, stock }) => {
  const { user } = useContext(UserContext)
  return useQuery(["branchProducts", user, { branchId, status, stock }], () =>
    getBranchProductsCount({ branchId, status, stock })
  )
}

export const useModifiers = ({ providerId }) => {
  const { user } = useContext(UserContext) // const authUser = useSelector((x) => x.auth.user);
  return useQuery(["modifiers", user, { providerId }], () =>
    getModifiers({ providerId })
  )
}
export const useCompletedOrders = ({
  providerId,
  completedStartDate,
  completedEndDate,
}) => {
  const { user } = useContext(UserContext)
  return useQuery(
    [
      "completedorders",
      user,
      { providerId, completedStartDate, completedEndDate },
    ],
    () =>
      getCompletedOrders({ providerId, completedStartDate, completedEndDate })
  )
}
export const useOngoingOrders = ({
  providerId,
  status,
  paymentStatus,
  startDate,
  endDate,
}) => {
  // console.log("useOnegoingOrders", providerId)
  const { user } = useContext(UserContext)
  return useQuery(
    [
      "ongoingorders",
      user,
      { providerId, status, paymentStatus, startDate, endDate },
    ],
    () =>
      getOngoingOrders({
        providerId,
        status,
        paymentStatus,
        startDate,
        endDate,
      })
  )
}

export const useProducts = ({
  providerId,
  status,
  category,
  startPrice,
  endPrice,
  options = { sort: { _id: -1 }, skip: 0, limit: 10 },
}) => {
  // const authUser = useSelector((x) => x.auth.user);
  const { user } = useContext(UserContext)
  return useQuery(
    [
      "products",
      user,
      { providerId, status, category, startPrice, endPrice },
      options,
    ],
    () =>
      getProducts({
        providerId,
        status,
        category,
        startPrice,
        endPrice,
        options,
      })
  )
}

export const useProductsCount = ({
  providerId,
  status,
  category,
  startPrice,
  endPrice,
}) => {
  // const authUser = useSelector((x) => x.auth.user);
  const { user } = useContext(UserContext)
  return useQuery(
    [
      "productsCount",
      user,
      { providerId, status, category, startPrice, endPrice },
    ],
    () =>
      getProductsCount({
        providerId,
        status,
        category,
        startPrice,
        endPrice,
      })
  )
}

export const useFilteringProducts = ({ providerId, searchName }) => {
  // const authUser = useSelector((x) => x.auth.user);
  const { user } = useContext(UserContext)
  return useQuery(["products", user, { providerId, searchName }], () =>
    getFilteringProducts({ providerId, searchName })
  )
}

export const useProductsModifiers = ({ menuId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["productsModifier", user, { menuId }], () =>
    getProductsModifiers({ menuId })
  )
}

export const useCategorySearch = ({ search, providerId }) => {
  // const authUser = useSelector((x) => x.auth.user);
  const { user } = useContext(UserContext)
  return useQuery(["categoriesDetails", user, { search, providerId }], () =>
    getCategorySearch({ search, providerId })
  )
}

export const useCategorySetting = ({ categoryId }) => {
  // const authUser = useSelector((x) => x.auth.user);
  const { user } = useContext(UserContext)
  return useQuery(["categoriesSetting", user, { categoryId }], () =>
    getCategorySetting({ categoryId })
  )
}
export const useProductCategorySearch = ({ search, providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["productcategoriesDetails", user, { search, providerId }],
    () => getProductCategorySearch({ search, providerId })
  )
}

// export const useCompletedOrders = ({ providerId }) => {
//   const authUser = useSelector((x) => x.auth.user);
//   return useQuery(["completedorders", authUser, { providerId }], () =>
//     getCompletedOrders({ providerId })
//   );
// };

// export const useOngoingOrders = ({ providerId }) => {
//   const authUser = useSelector((x) => x.auth.user);
//   return useQuery(["ongoingorders", authUser, { providerId }], () =>
//     getOngoingOrders({ providerId })
//   );
// };

// export const useOneOrderDetails = ({ orderId }) => {
//   const authUser = useSelector((x) => x.auth.user);
//   return useQuery(["orderDetails", authUser, { orderId }], () =>
//     getOneOrderDetail({ orderId })
//   );
// };

export const useSpecificSubcatogories = ({ subId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["specificSubcatogries", user, { subId }], () =>
    getSpecificSubcatogories({ subId })
  )
}

export const useSpecificSubcatogoriesSetting = ({ subCategoryId }) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["specificSubcatogriesSetting", user, { subCategoryId }],
    () => getSpecificSubcatogoriesSetting({ subCategoryId })
  )
}

export const useReviews = ({ providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["reviews", user, { providerId }], () =>
    getReviews({ providerId })
  )
}

export const useAddons = ({ providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["addons", user, { providerId }], () =>
    getAddons({ providerId })
  )
}
export const useBranches = ({ providerId }) => {
  // console.log("branches providerId ", providerId)
  const { user } = useContext(UserContext)
  return useQuery(["branches", user, { providerId }], () =>
    getBranches({ providerId })
  )
}

export const useCoupons = ({ providerId }) => {
  // console.log("coupon providerId ", `${providerId}`)
  return useQuery(["coupons", { providerId }], () => getCoupons({ providerId }))
}

export const usePrefixCheck = ({ fieldValue, branchId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["field", user, { fieldValue, branchId }], () =>
    getPrefixCheck({ fieldValue, branchId })
  )
}

export const useBranchesSearch = ({ providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["branches", user, { providerId }], () =>
    getBranchesSearch({ providerId })
  )
}

export const useOrdersBtwDates = ({
  startDate,
  endDate,
  providerId,
  branchId,
}) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["orderbtwdates", user, { startDate, endDate, providerId, branchId }],
    () =>
      getOrdersBtwDates(
        { startDate, endDate, providerId, branchId },
        {
          enabled: false, // Disable automatic query execution
        }
      )
  )
}

export const useBookingsBtwDates = ({
  startDate,
  endDate,
  providerId,
  branchId,
}) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["bookingsbtwdates", user, { startDate, endDate, providerId, branchId }],
    () =>
      getBookingsBtwDates(
        { startDate, endDate, providerId, branchId },
        {
          enabled: false, // Disable automatic query execution
        }
      )
  )
}

// export const useStripeProducts = () => {
//   const authUser = useSelector((x) => x.auth.user);
//   return useQuery(["stripeProducts", authUser], () => getStripeProducts());
// };

// export const useOrdersBtwDates = ({
//   startDate,
//   endDate,
//   providerId,
//   branchId,
// }) => {
//   const authUser = useSelector((x) => x.auth.user);
//   return useQuery(
//     ["btwdates", authUser, { startDate, endDate, providerId, branchId }],
//     () => getOrdersBtwDates({ startDate, endDate, providerId, branchId })
//   );
// };

export const useOrdersThisYear = ({
  startOfYear,
  endOfYear,
  providerId,
  branchId,
}) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["thisYear", user, { startOfYear, endOfYear, providerId, branchId }],
    () => getOrdersThisyear({ startOfYear, endOfYear, providerId, branchId })
  )
}

export const useOrdersThisMonth = ({
  startOfMonth,
  endOfMonth,
  providerId,
  branchId,
}) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["btwdates", user, { startOfMonth, endOfMonth, providerId, branchId }],
    () => getOrdersThisMonth({ startOfMonth, endOfMonth, providerId, branchId })
  )
}

export const useOrdersThisWeek = ({
  startOfWeek,
  endOfWeek,
  providerId,
  branchId,
}) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["btwdates", user, { startOfWeek, endOfWeek, providerId, branchId }],
    () => getOrdersThisWeek({ startOfWeek, endOfWeek, providerId, branchId })
  )
}

export const useOrdersTodays = ({
  startTodays,
  endTodays,
  providerId,
  branchId,
}) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["btwdates", user, { startTodays, endTodays, providerId, branchId }],
    () => getOrdersThisDay({ startTodays, endTodays, providerId, branchId })
  )
}

export const useBranchCity = () => {
  const { user } = useContext(UserContext)
  return useQuery(["branchCity", user], () => getBranchCity())
}

export const useBranchCitySetting = ({ cityId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["branchCitySetting", user, { cityId }], () =>
    getBranchCitySetting({ cityId })
  )
}
export const useOneOrderDetails = ({ orderId }) => {
  // console.log("querycalls id", orderId)
  // const authUser = useSelector((x) => x.auth.user);
  const { user } = useContext(UserContext)
  return useQuery(["orderDetails", user, { orderId }], () =>
    getOneOrderDetail({ orderId })
  )
}

export const useAllCategory = ({
  providerId,
  options = { sort: { _id: -1 }, skip: 0, limit: 10 },
}) => {
  const { user } = useContext(UserContext)
  return useQuery(["allCategory", user, { providerId }, options], () =>
    getAllCategory({ providerId, options })
  )
}

export const useFetchAllCategory = ({ providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["allCategory", user, { providerId }], () =>
    getFetchAllCategory({ providerId })
  )
}

export const useCategoryCount = ({ providerId, options = {} }) => {
  const { user } = useContext(UserContext)
  return useQuery(["allCategoryCount", user, { providerId }, options], () =>
    getAllCategoryCount({ providerId, options })
  )
}

export const useAudience = ({
  providerId,
  options = { sort: { _id: -1 }, skip: 0, limit: 10 },
}) => {
  const { user } = useContext(UserContext) // const authUser = useSelector((x) => x.auth.user);
  return useQuery(["audience", user, { providerId }, options], () =>
    getAudience({ providerId, options })
  )
}

export const useAudienceCount = ({ providerId }) => {
  const { user } = useContext(UserContext) // const authUser = useSelector((x) => x.auth.user);
  return useQuery(["audience", user, { providerId }], () =>
    getAudienceCount({ providerId })
  )
}

export const useAudienceOrder = ({ providerId }) => {
  const { user } = useContext(UserContext) // const authUser = useSelector((x) => x.auth.user);
  return useQuery(["audienceOrder", user, { providerId }], () =>
    getAudienceOrder({ providerId })
  )
}

export const useChatList = ({ providerId, branchId, customerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["chatList", user, { providerId, branchId, customerId }],
    () => getChatList({ providerId, branchId, customerId })
  )
}

export const useCustomerOrders = ({ customerId, providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["customerOrders", user, { customerId, providerId }], () =>
    getCustomerOrders({ customerId, providerId })
  )
}

export const useCustomerBookings = ({ customerId, providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["customerBookings", user, { customerId, providerId }], () =>
    getCustomerBookings({ customerId, providerId })
  )
}

export const useCustomerDetails = ({ customerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["customerDetails", user, { customerId }], () =>
    getCustomerDetails({ customerId })
  )
}

export const useInCompleteOrders = ({ providerId, customerId }) => {
  // console.log("useOnegoingOrders", providerId)
  const { user } = useContext(UserContext)
  return useQuery(["incompleteorders", user, { providerId, customerId }], () =>
    getInCompleteOrders({ providerId, customerId })
  )
}

export const useLastOrder = ({ providerId }) => {
  // console.log("useOnegoingOrders", providerId)
  const { user } = useContext(UserContext)
  return useQuery(["lastOrder", user, { providerId }], () =>
    getLastOrder({ providerId })
  )
}

export const useAddresses = ({ entityId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["addreses", user, { entityId }], () =>
    getAddress({ entityId })
  )
}

export const useOneMenu = ({ menuId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["oneMenu", user, { menuId }], () => getOneMenu({ menuId }))
}

export const useProductVariants = ({ providerId, isSelectOption }) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["productVariant", user, { providerId, isSelectOption }],
    () => getProductVariants({ providerId, isSelectOption })
  )
}

export const useOneProductVariant = ({ itemId, providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["oneProductVariant", user, { itemId, providerId }], () =>
    getOneProductVariants({ itemId, providerId })
  )
}

export const useRevenue = ({ dateFrom, dateTo, providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["Revenue", user, { dateFrom, dateTo, providerId }], () =>
    getRevenue({ dateFrom, dateTo, providerId })
  )
}

export const useServiceCategories = ({ providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["serviceCategories", user, { providerId }], () =>
    getServiceCategories({ providerId })
  )
}

export const useOneServiceCategory = ({ id }) => {
  const { user } = useContext(UserContext)
  return useQuery(["serviceCategory", user, { id }], () =>
    getOneServiceCategory({ id })
  )
}

export const useServiceCategoryData = ({ categoryId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["categoriesSetting", user, { categoryId }], () =>
    getServiceCategorySetting({ categoryId })
  )
}

export const useServiceCategorySearch = ({ search, providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["serviceCategorySearch", user, { search, providerId }], () =>
    getServiceCategorySearch({ search, providerId })
  )
}

export const useServices = ({ providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["services", user, { providerId }], () =>
    getServices({ providerId })
  )
}

export const useOneService = ({ id }) => {
  const { user } = useContext(UserContext)
  return useQuery(["oneService", user, { id }], () => getOneService({ id }))
}

export const useCms = ({ providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["cms", user, { providerId }], () =>
    getCms({ providerId })
  )
}

export const useOneCms = ({ id }) => {
  const { user } = useContext(UserContext)
  return useQuery(["oneCms", user, { id }], () => getOneCms({ id }))
}

export const useTeamMembers = ({ providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["getTeamMembers", user, { providerId }], () =>
    getTeamMembers({ providerId })
  )
}

export const useOneTeamMember = ({ id }) => {
  const { user } = useContext(UserContext)
  return useQuery(["getOneTeamMember", user, { id }], () =>
    getOneTeamMember({ id })
  )
}

export const useServiceCategoryTeamMembers = ({ providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["getServiceCategoryTeamMembers", user, { providerId }], () =>
    getServiceCategoryTeamMembers({ providerId })
  )
}

export const useServicesTeamMembers = ({ providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["getServicesTeamMembers", user, { providerId }], () =>
    getServicesTeamMembers({ providerId })
  )
}

export const useBookings = ({
  providerId,
  status,
  paymentStatus,
  startDate,
  endDate,
  assignedTo,
  service,
}) => {
  const { user } = useContext(UserContext)
  return useQuery(["services", user, { providerId, status, paymentStatus, startDate, endDate, assignedTo, service }], () =>
    getBookings({
      providerId,
      status,
      paymentStatus,
      startDate,
      endDate,
      assignedTo,
      service,
    })
  )
}

export const useOneBookingDetails = ({ id }) => {
  const { user } = useContext(UserContext)
  return useQuery(["getOneBookingDetails", user, { id }], () =>
    getOneBookingDetails({ id })
  )
}

export const useLastBooking = ({ providerId }) => {
  // console.log("useOnegoingOrders", providerId)
  const { user } = useContext(UserContext)
  return useQuery(["getLastBooking", user, { providerId }], () =>
    getLastBooking({ providerId })
  )
}

export const useBookingsThisYear = ({
  startOfYear,
  endOfYear,
  providerId,
  branchId,
}) => {
  const { user } = useContext(UserContext)
  return useQuery(
    [
      "getBookingsThisyear",
      user,
      { startOfYear, endOfYear, providerId, branchId },
    ],
    () => getBookingsThisyear({ startOfYear, endOfYear, providerId, branchId })
  )
}

export const useBookingsThisMonth = ({
  startOfMonth,
  endOfMonth,
  providerId,
  branchId,
}) => {
  const { user } = useContext(UserContext)
  return useQuery(
    [
      "getBookingsThisMonth",
      user,
      { startOfMonth, endOfMonth, providerId, branchId },
    ],
    () =>
      getBookingsThisMonth({ startOfMonth, endOfMonth, providerId, branchId })
  )
}

export const useBookingsThisWeek = ({
  startOfWeek,
  endOfWeek,
  providerId,
  branchId,
}) => {
  const { user } = useContext(UserContext)
  return useQuery(
    [
      "getBookingsThisWeek",
      user,
      { startOfWeek, endOfWeek, providerId, branchId },
    ],
    () => getBookingsThisWeek({ startOfWeek, endOfWeek, providerId, branchId })
  )
}

export const useBookingsTodays = ({
  startTodays,
  endTodays,
  providerId,
  branchId,
}) => {
  const { user } = useContext(UserContext)
  return useQuery(
    [
      "getBookingsThisDay",
      user,
      { startTodays, endTodays, providerId, branchId },
    ],
    () => getBookingsThisDay({ startTodays, endTodays, providerId, branchId })
  )
}

export const useBookingServices = ({ providerId }) => {
  // const authUser = useSelector((x) => x.auth.user);
  const { user } = useContext(UserContext)
  return useQuery(["getBookingServices", user, { providerId }], () =>
    getBookingServices({ providerId })
  )
}

export const useAudienceBookings = ({ providerId }) => {
  const { user } = useContext(UserContext) // const authUser = useSelector((x) => x.auth.user);
  return useQuery(["getAudienceBookings", user, { providerId }], () =>
    getAudienceBookings({ providerId })
  )
}

export const useServiceMembers = ({ providerId }) => {
  const { user } = useContext(UserContext) // const authUser = useSelector((x) => x.auth.user);
  return useQuery(["getServiceMembers", user, { providerId }], () =>
    getServiceMembers({ providerId })
  )
}

export const useCouponProducts = ({ providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["CouponProducts", user, { providerId }], () =>
    getCouponProducts({
      providerId,
    })
  )
}

export const useCouponCustomers = ({ providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["CouponCustomers", user, { providerId }], () =>
    getCouponCustomer({
      providerId,
    })
  )
}
