import React, { useState, useRef, useEffect } from "react"
import "../../css/service-categories.css"
import { Typography, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import CircularProgress from "@mui/material/CircularProgress"
import { useNavigate, useParams } from "react-router-dom"
import { IKContext, IKImage, IKUpload, IKVideo } from "imagekitio-react"
import {
  urlEndpoint,
  publicKey,
  authenticationEndpoint,
} from "../../components/ImageKit/ImageKitCred"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import {
  useCurrentProvider,
  useOneServiceCategory,
} from "common/Hooks/queryCalls"
import { LottieLoading } from "common/LottieLoading"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import {
  addServiceCategory,
  updateServiceCategory,
} from "common/Services/dbServices"
const AddServiceCategories = () => {
  const inputRefMainImage = useRef(null)
  const [imageUploading, setImageUploading] = useState(false)
  const [resIcon, setResIcon] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const [errorMessage, setErrorMessage] = useState("")
  const validationSchema = Yup.object().shape({
    englishName: Yup.string().required(" Name is required"),
    // arabicName: Yup.string().required("اسم is required"),
  })
  const [checked, setChecked] = React.useState(false)
  const currentProvider = useCurrentProvider()
  const handleChange = event => {
    setChecked(event.target.checked)
  }
  const {
    data,
    isLoading: isLoadingData,
    refetch,
    isSuccess,
  } = useOneServiceCategory({
    id,
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const addNewServiceCategory = async data => {
    if (id) {
      setIsLoading(true)
      try {
        const result = await updateServiceCategory({
          itemId: id,
          data,
          resIcon,
          isActive: checked,
        })
        // console.log("update:", result)
        setIsLoading(false)
        refetch()
        navigate("/service-categories")
      } catch (error) {
        setIsLoading(false)
      }
    } else {
      setIsLoading(true)
      // console.log("data:", data)
      try {
        const result = await addServiceCategory({
          providerId: currentProvider?.data?._id,
          data,
          resIcon,
          isActive: checked,
        })
        // console.log("add:", result)
        setIsLoading(false)
        navigate("/service-categories")
      } catch (error) {
        setIsLoading(false)
      }
    }
  }
  const onErrorIcon = err => {
    setImageUploading(false)
    setImageShowError(true)
  }

  const onSuccessIcon = res => {
    // console.log("resImage:", res)
    setResIcon(res)
    setImageUploading(false)
  }

  const onUploadProgress = progress => {
    if (progress) {
    }
    setImageUploading(true)
  }

  useEffect(() => {
    setValue("englishName", data?.name?.en)
    setValue("arabicName", data?.name?.en)
    setResIcon(data?.image)
    setChecked(data?.isActive)
  }, [isSuccess])
  // console.log("currentProvider:", currentProvider?.data?._id?.toString())

  if (isLoadingData) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  return (
    <div className="service-category-div">
      <h5 className="service-category-edit-title">
        {id ? `Edit Service Category` : `Add New Service Category`}
      </h5>{" "}
      <form>
        <div className="service-category-text-field">
          <div className="service-category-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Name*"
              id="fullWidth"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("englishName", { required: true })}
              error={errors.englishName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.englishName && errors.englishName?.message
                ? errors.englishName?.message.toString()
                : null}
            </Typography>
            {/* <TextField
              fullWidth
              label="اسم*"
              id="fullWidth"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("arabicName", { required: true })}
              error={errors.arabicName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.arabicName && errors.arabicName?.message
                ? errors.arabicName?.message.toString()
                : null}
            </Typography> */}
          </div>
          <div>
            <FormControlLabel
              control={<Switch checked={checked} onChange={handleChange} />}
              label="Is Active"
              labelPlacement="start"
            />
          </div>

          <div className="service-category-media-div">
            <div className="service-category-image-field">
              <h6 style={{ display: "flex", flexWrap: "wrap", width: "50px" }}>
                Image:
              </h6>

              {resIcon && resIcon.filePath ? (
                <div>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <IKImage
                      urlEndpoint={urlEndpoint}
                      path={resIcon.filePath}
                      style={{
                        width: "calc(150px - 30px)",
                        height: "100px",
                        borderRadius: "10px",
                      }}
                    />
                    {resIcon.filePath && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          paddingTop: "2px",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            "@media (max-width: 600px)": {
                              fontSize: "0.6rem",
                              backgroundColor: "#040D12",
                              width: "100%",
                            },
                            "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                fontSize: "0.6rem",
                                backgroundColor: "#040D12",
                                width: "100%",
                              },
                            "@media (min-width: 970px)": {
                              fontSize: "0.6rem",
                              backgroundColor: "#040D12",
                              width: "100%",
                            },
                          }}
                          onClick={() => {
                            //  setImage(null);
                            setResIcon(null)
                          }}
                          style={{ fontFamily: "outfit" }}
                        >
                          Delete
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <IKContext
                  publicKey={publicKey}
                  urlEndpoint={urlEndpoint}
                  authenticationEndpoint={authenticationEndpoint}
                >
                  {imageUploading ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress size={25} />
                    </Box>
                  ) : (
                    <>
                      <IKUpload
                        onError={onErrorIcon}
                        onSuccess={onSuccessIcon}
                        onUploadStart={onUploadProgress}
                        buttonProps={{ children: "+" }}
                        inputRef={inputRefMainImage}
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={e => {
                          // Check if the selected file is an image
                          const selectedFile = e.target.files[0]
                          if (selectedFile) {
                            if (!selectedFile.type.startsWith("image/")) {
                              // Check the file type and show an appropriate error message
                              if (
                                selectedFile.type.startsWith("application/pdf")
                              ) {
                                setErrorMessage(
                                  "Please select an image, not a PDF."
                                )
                              } else if (
                                selectedFile.type.startsWith("video/")
                              ) {
                                setErrorMessage(
                                  "Please select an image, not a video."
                                )
                              } else {
                                setErrorMessage("Please select an image file.")
                              }
                              // Clear the file input (optional)
                              e.target.value = null
                            } else {
                              // Clear any previous error messages
                              setErrorMessage("")
                            }
                          }
                        }}
                      />
                      <div
                        className="image-upload-btn-div"
                        onClick={() => inputRefMainImage.current.click()}
                      >
                        <FileUploadIcon />
                        <Button
                          sx={{
                            "@media (max-width: 600px)": {
                              fontSize: "0.6rem",
                            },
                            "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                fontSize: "0.8rem",
                              },
                            "@media (min-width: 970px)": {
                              fontSize: "0.8rem",
                            },
                          }}
                          variant="standard"
                          // onClick={() => inputRefMainImage.current.click()}
                          style={{ fontFamily: "outfit" }}
                        >
                          Upload
                        </Button>
                      </div>
                    </>
                  )}
                </IKContext>
              )}
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
          </div>
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          {!isLoading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => navigate(-1)}
            >
              Close
            </Button>
          ) : (
            ""
          )}
          {isLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Save
            </LoadingButton>
          ) : imageUploading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              disabled
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewServiceCategory)}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  )
}

export default AddServiceCategories
