import React, { useState, useEffect } from "react"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import DeleteIcon from "@mui/icons-material/Delete"
import EditNoteIcon from "@mui/icons-material/EditNote"
import { Button, IconButton } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CancelIcon from "@mui/icons-material/Cancel"
import Alert from "@mui/material/Alert"
import Stack from "@mui/material/Stack"
import Snackbar from "@mui/material/Snackbar"
import { LottieLoading } from "common/LottieLoading"
import { useNavigate } from "react-router-dom"
import "../../css/service-categories.css"
import {
  useCurrentProvider,
  useServiceCategories,
} from "common/Hooks/queryCalls"
import { deleteServiceCategory } from "common/Services/dbServices"
const ServiceCategories = () => {
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [openDialog, setOpenDialog] = useState(false)
  const [deleteItem, setDeleteItem] = useState({})
  const [isDeleteLoading, setIsDeleteLoading] = useState(false)
  const currentProvider = useCurrentProvider()
  const { data, isLoading, refetch } = useServiceCategories({
    providerId: currentProvider?.data?._id,
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleClickDelete = async () => {
    setIsDeleteLoading(true)
    try {
      const result = await deleteServiceCategory({
        itemId: deleteItem?._id,
        providerId: currentProvider?.data?._id,
      })
      setOpenDialog(false)
      setIsDeleteLoading(false)
      refetch()
    } catch (error) {
      setIsDeleteLoading(false)
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setDeleteItem({})
  }

  if (isLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  // console.log("serviceCategory:", data)
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ fontFamily: "outfit" }}>
          {"Are you sure you want to delete this Service Category?"}
        </DialogTitle>

        <DialogActions>
          {!isDeleteLoading ? (
            <Button
              onClick={handleCloseDialog}
              style={{ fontFamily: "outfit" }}
            >
              No
            </Button>
          ) : (
            ""
          )}
          {isDeleteLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Yes
            </LoadingButton>
          ) : (
            <Button
              onClick={handleClickDelete}
              autoFocus
              style={{ fontFamily: "outfit" }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <div className="page-title-box">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            {" "}
            <h6 className="page-title">Service Categories</h6>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Diving into Service Categories
              </li>
            </ol>
          </div>
          <div className="d-flex align-items-center">
            <Button
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#333547",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                },
              }}
              variant="contained"
              onClick={() => {
                navigate("/add-service-category")
              }}
              style={{ fontFamily: "outfit" }}
            >
              Add Service Category
            </Button>
          </div>
        </div>
      </div>
      <div>
        {" "}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              {data?.length == 0 && (
                <caption style={{ fontFamily: "outfit" }}>
                  No Items Available
                </caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Image
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Is Active
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={tableHeadStyle}
                    style={{ fontFamily: "outfit" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(item => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={item._id}
                      >
                        {!item?.name?.ar && !item?.name?.en ? (
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            -
                          </TableCell>
                        ) : (
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            {item?.name?.ar
                              ? item?.name?.en
                                ? `${item?.name?.en}`
                                : `${item?.name?.ar}`
                              : `${item?.name?.en}`}
                          </TableCell>
                        )}

                        <TableCell align="center" sx={tableBodyStyle}>
                          <img
                            src={item?.image?.url}
                            style={{ width: "50px", height: "50px" }}
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableBodyStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          {item?.isActive ? (
                            <CheckCircleIcon />
                          ) : (
                            <CancelIcon />
                          )}
                        </TableCell>

                        <TableCell align="center">
                          {
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  navigate(
                                    `/edit-service-category/${item?._id}`
                                  )
                                }}
                              >
                                <EditNoteIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setOpenDialog(true)
                                  setDeleteItem(item)
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </div>
                          }
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={tableFooterStyle}
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={data?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </>
  )
}

export default ServiceCategories
const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}

const tableFooterStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.6rem",
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
}
