import PropTypes from "prop-types"
import React, { useState, useEffect, useContext } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Container,
  Label,
  Form,
  FormFeedback,
  Input,
  Alert,
} from "reactstrap"
import { app } from "common/Services/dbServices"

// Redux
import { connect, useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { loginUser, apiError } from "../../store/actions"
import { getUser } from "common/Services/dbServices"

// import images
import logoSm from "../../assets/images/logo-sm.png"
import { UserContext } from "Context/userContext"
import { LoadingButton } from "@mui/lab"
import SaveIcon from "@mui/icons-material/Save"

const Login = props => {
  const navigate = useNavigate()
  const { fetchUser, emailPasswordLogin, user } = useContext(UserContext)
  const [loading, setLoading] = React.useState(false)
  const [formError, setFormError] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [form, setForm] = useState({
    email: "",
    password: "",
  })

  const onFormInputChange = event => {
    const { name, value } = event.target
    setForm({ ...form, [name]: value })
  }
  const redirectNow = () => {
    //console.log("welcome")
    const redirectTo = location.search.replace("?redirectTo=", "")
    navigate(redirectTo ? redirectTo : "/")
  }

  const loadUser = async () => {
    if (!user) {
      const fetchedUser = await fetchUser()
      // console.log(fetchedUser)
      if (fetchedUser) {
        redirectNow()
      }
    }
  }

  const onSubmit = async event => {
    setFormError(false)
    setLoginError(false)
    setLoading(true)
    if (form.email && form.password) {
      try {
        const email = form.email
        const password = form.password
        const result = await emailPasswordLogin(email, password)
        // console.log("logged result:", result)
        if (result) {
          if (app.currentUser) {
            localStorage.setItem("authUser", JSON.stringify(app.currentUser))
            redirectNow()
          }
          setLoading(false)
        }
      } catch (error) {
        // alert(error)
        setLoading(false)
        setLoginError(true)
        setTimeout(() => {
          setLoginError(false)
        }, 5000)
      }
    } else {
      // alert("Please Complete Form")
      setLoading(false)
      setFormError(true)
      setTimeout(() => {
        setFormError(false)
      }, 5000)
    }
  }

  useEffect(() => {
    loadUser()
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: form.email,
      password: form.password,
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
  })

  // console.log("values,",validation.values.email,validation.values.password)
  document.title = "Login | Merche "
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            {loginError ? (
              <Alert color="danger">invalid username/password!</Alert>
            ) : (
              ""
            )}

            {formError ? (
              <Alert color="danger">Enter Email and Password Corrrectly!</Alert>
            ) : (
              ""
            )}
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="bg-primary">
                  <div
                    className="text-primary text-center p-4"
                    style={{ backgroundColor: "#333547" }}
                  >
                    <h5 className="text-white font-size-20">Welcome Back !</h5>
                    <p className="text-white-50">
                      Sign in to continue to Merche Bussiness Portal.
                    </p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="24" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <Form
                      className="mt-4"
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                      action="#"
                    >
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="username">
                          Email
                        </Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Your Email"
                          type="email"
                          id="username"
                          onChange={onFormInputChange}
                          onBlur={validation.handleBlur}
                          value={form.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="userpassword">
                          Password
                        </Label>
                        <Input
                          name="password"
                          value={form.password || ""}
                          type="password"
                          className="form-control"
                          placeholder="Enter Your Password"
                          onChange={onFormInputChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                            validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                        validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3 row">
                        <div className="col-sm-6">
                          {/* <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div> */}
                        </div>
                        <div className="col-sm-6 text-end">
                          {loading ? (
                            <LoadingButton
                              color="secondary"
                              loading
                              loadingPosition="start"
                              startIcon={<SaveIcon />}
                              variant="contained"
                              className="btn btn-primary w-md waves-effect waves-light"
                            >
                              <span>Log In</span>
                            </LoadingButton>
                          ) : (
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                              onClick={onSubmit}
                              style={{
                                backgroundColor: "#333547",
                                border: "none",
                              }}
                            >
                              Log In
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <Link
                            to="/forgot-password"
                            style={{ color: "#333547" }}
                          >
                            <i className="mdi mdi-lock"></i> Forgot your
                            password?
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {/* <p>
                  Don&#39;t have an account ?{" "}
                  <Link
                    to="/register"
                    className="fw-medium "
                    style={{ color: "#333547" }}
                  >
                    {" "}
                    Sign Up now{" "}
                  </Link>{" "}
                </p> */}
                <p>© {new Date().getFullYear()} Merche Crafted by CodeRythm</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}
