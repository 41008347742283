import PropTypes from "prop-types"
import React, { useState, useRef } from "react"
import ListGroup from "react-bootstrap/ListGroup"
import "../../css/dashboard-details.css"
import {
  useCurrentProvider,
  useProvider,
  useBranches,
  usePrefixCheck,
} from "../../common/Hooks/queryCalls"
import { Button } from "@mui/material"
import * as yup from "yup"
import { Alert } from "react-bootstrap"
import { LottieLoading } from "../../common/LottieLoading"
import { Container, Row, Col } from "reactstrap"

import { withTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const Details = props => {
  const navigate = useNavigate()
  const [menu, setMenu] = useState(false)
  const [show, setShow] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [showImageError, setShowImageError] = useState(false)
  const validationSchema = yup.object().shape({
    englishName: yup.string().required(),
    number: yup.string().required(),
    // username: yup.string().required(),
    upiId: yup.string().required(),
    branchAddress: yup.string().required("Address is required"),
    deliveryCharge: yup
      .number()
      .typeError("Delivery Charge  must be a number")
      .required("Delivery Charge is required"),
    prefix: yup.string().required("Prefix is required"),
  })

  const handleClose = () => setShow(false)

  const currentProvider = useCurrentProvider()

  const { data, isLoading, refetch, isError } = useProvider({
    providerId: currentProvider?.data?._id,
  })
  const {
    data: branchData,
    isLoading: branchLoading,
    refetch: branchRefetch,
  } = useBranches({
    providerId: currentProvider?.data?._id,
  })

  if (isLoading) {
    return <h1>{<LottieLoading />}</h1>
  }
  if (data?.length == 0) {
    return <h1>No Items Available</h1>
  }

  if (branchLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  // console.log("branchData:", branchData)
  // console.log("checkPrefix:", prefixCheck)
  // console.log("numberCheck:", numberCheck)

  if (showSuccess) {
    setInterval(() => setShowSuccess(false), 5000)
  }
  const toggle = () => {
    setMenu(!menu)
  }
  document.title = "Merche - Business"

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ marginTop: "-80px" }}
            >
              <div>
                {" "}
                <h6 className="page-title">Details</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Exploring Your Shop's Information
                  </li>
                </ol>
              </div>

              <div className="d-flex align-items-center">
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                  }}
                  variant="contained"
                  onClick={() => navigate(`/editDetails`)}
                  style={{ fontFamily: "outfit" }}
                >
                  Edit
                </Button>
              </div>
            </div>
          </div>
          <>
            <div>
              {isError ? (
                <Alert
                  variant="danger"
                  onClose={() => setShowSuccess(false)}
                  dismissible
                >
                  Something went wrong
                </Alert>
              ) : (
                ""
              )}
              {showSuccess && (
                <Alert
                  variant="success"
                  onClose={() => setShowSuccess(false)}
                  dismissible
                >
                  Provider Updated Successfully!
                </Alert>
              )}
              {showError && (
                <Alert
                  variant="danger"
                  onClose={() => setShowError(false)}
                  dismissible
                >
                  Something went wrong!
                </Alert>
              )}
              {showImageError && (
                <Alert
                  variant="danger"
                  onClose={() => showImageError(false)}
                  dismissible
                >
                  Image uploading failed. Please try again later
                </Alert>
              )}

              <ListGroup style={{ marginTop: "-50px" }}>
                <ListGroup.Item>
                  <div className="listgroupitem-row">
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Name:</h6>
                      <h6 className="eachitem-div-h6">{data?.name?.en}</h6>
                    </div>
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Description:</h6>
                      <h6 className="eachitem-div-h6">
                        {data?.description?.en}
                      </h6>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="listgroupitem-row">
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Number:</h6>
                      <h6 className="eachitem-div-h6">{`+91 ${data?.number}`}</h6>
                    </div>
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Email:</h6>
                      <h6 className="eachitem-div-h6">{data?.email}</h6>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="listgroupitem-row">
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Whatsapp:</h6>
                      <h6 className="eachitem-div-h6">{`+91 ${data?.whatsapp}`}</h6>
                    </div>
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Instagram:</h6>
                      <h6 className="eachitem-div-h6">{data?.instagram}</h6>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="listgroupitem-row">
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Facebook:</h6>
                      <h6 className="eachitem-div-h6">{data?.facebook}</h6>
                    </div>
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Prefix:</h6>
                      <h6 className="eachitem-div-h6">
                        {branchData?.length > 0 ? branchData[0]?.prefix : `-`}
                      </h6>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="listgroupitem-row">
                    <div className="eachitem-div">
                      <h6 className="eachitem-div-h6">Address:</h6>
                      <h6 className="eachitem-div-h6">
                        {branchData?.length > 0 ? branchData[0]?.address : ""}
                      </h6>
                    </div>
                  </div>
                </ListGroup.Item>
                {currentProvider?.data?.type === "e-commerce" ? (
                  <ListGroup.Item>
                    <div className="listgroupitem-row">
                      <div className="eachitem-div">
                        <h6 className="eachitem-div-h6">Delivery Charge:</h6>
                        <h6 className="eachitem-div-h6">
                          {branchData?.length > 0
                            ? branchData[0]?.deliveryCharge
                            : ""}
                        </h6>
                      </div>
                      <div className="eachitem-div">
                        <h6 className="eachitem-div-h6">UPI ID:</h6>
                        <h6 className="eachitem-div-h6">
                          {data?.upiId || `-`}
                        </h6>
                      </div>
                    </div>
                  </ListGroup.Item>
                ) : null}
                {data?.icon?.url || data?.mainImage?.url ? (
                  <ListGroup.Item>
                    <div className="listgroupitem-row">
                      {data?.icon?.url ? (
                        <div className="eachitem-div">
                          <h6 className="eachitem-div-h6">Icon:</h6>
                          <h6 className="eachitem-div-h6">
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={data?.icon?.url}
                              alt="image not available"
                            />
                          </h6>
                        </div>
                      ) : (
                        ""
                      )}
                      {data?.mainImage?.url ? (
                        <div className="eachitem-div">
                          <h6 className="eachitem-div-h6">Banner:</h6>
                          <h6 className="eachitem-div-h6">
                            {" "}
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={data?.mainImage?.url}
                              alt="image not available"
                            />
                          </h6>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                {currentProvider?.data?.type === "e-commerce" &&
                branchData &&
                branchData[0]?.inStockStatus ? (
                  <ListGroup.Item>
                    <div className="listgroupitem-row">
                      <div className="eachitem-div">
                        <h6 className="eachitem-div-h6">Store Location:</h6>
                        <h6 className="eachitem-div-h6">
                          {branchData && branchData[0]?.storeAddress}
                        </h6>
                      </div>
                    </div>
                  </ListGroup.Item>
                ) : null}
              </ListGroup>
            </div>
          </>
        </Container>
      </div>
    </React.Fragment>
  )
}

Details.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(Details)
