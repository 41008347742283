import React, { useState, useRef, useEffect } from "react"
import "../../css/team-members.css"
import { Typography, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import CircularProgress from "@mui/material/CircularProgress"
import { useNavigate, useParams } from "react-router-dom"
import { IKContext, IKImage, IKUpload, IKVideo } from "imagekitio-react"
import {
  urlEndpoint,
  publicKey,
  authenticationEndpoint,
} from "../../components/ImageKit/ImageKitCred"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import {
  useCurrentProvider,
  useOneTeamMember,
  useServiceCategoryTeamMembers,
  useServicesTeamMembers,
} from "common/Hooks/queryCalls"
import { LottieLoading } from "common/LottieLoading"
import Switch from "@mui/material/Switch"
import FormControlLabel from "@mui/material/FormControlLabel"
import { addTeamMember, updateTeamMember } from "common/Services/dbServices"
import Select from "react-select"
import dayjs from "dayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import Checkbox from "@mui/material/Checkbox"

const AddTeamMembers = () => {
  const inputRefMainImage = useRef(null)
  const [imageUploading, setImageUploading] = useState(false)
  const [resIcon, setResIcon] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const [errorMessage, setErrorMessage] = useState("")
  const [selectedServiceCategories, setSelectedServiceCategories] = useState([])
  const [selectedServices, setSelectedServices] = useState([])
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [isServiceChecked, setServiceChecked] = useState(false)
  const [isServiceCategoriesChecked, setServiceCategoriesChecked] =
    useState(false)
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required(" Full Name is required"),
    number: Yup.string().required("Number is required"),
  })
  const [checked, setChecked] = React.useState(false)
  const currentProvider = useCurrentProvider()
  const handleChange = event => {
    setChecked(event.target.checked)
  }
  const {
    data,
    isLoading: isLoadingData,
    refetch,
    isSuccess,
  } = useOneTeamMember({
    id,
  })

  const ServiceCategoryTeamMembersData = useServiceCategoryTeamMembers({
    providerId: currentProvider?.data?._id,
  })

  const ServicesTeamMembersData = useServicesTeamMembers({
    providerId: currentProvider?.data?._id,
  })

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const addNewTeamMember = async data => {
    if (
      selectedServices?.length > 0 &&
      startTime !== null &&
      endTime !== null
    ) {
      if (id) {
        setIsLoading(true)
        try {
          const result = await updateTeamMember({
            itemId: id,
            data,
            resIcon,
            isActive: checked,
            categoryIds: selectedServiceCategories.map(
              service => service.value
            ),
            serviceIds: selectedServices.map(service => service.value),
            startTime:
              startTime !== null ? dayjs(startTime).format("LT") : null,
            endTime: endTime !== null ? dayjs(endTime).format("LT") : null,
          })
          // console.log("update:", result)
          setIsLoading(false)
          refetch()
          navigate("/team-members")
        } catch (error) {
          setIsLoading(false)
        }
      } else {
        setIsLoading(true)
        // console.log("data:", data)
        try {
          const result = await addTeamMember({
            providerId: currentProvider?.data?._id,
            data,
            resIcon,
            isActive: checked,
            categoryIds: selectedServiceCategories.map(
              service => service.value
            ),
            serviceIds: selectedServices.map(service => service.value),
            startTime:
              startTime !== null ? dayjs(startTime).format("LT") : null,
            endTime: endTime !== null ? dayjs(endTime).format("LT") : null,
          })
          // console.log("add:", result)
          setIsLoading(false)
          navigate("/team-members")
        } catch (error) {
          setIsLoading(false)
        }
      }
    } else {
      setIsLoading(false)
    }
  }
  const onErrorIcon = err => {
    setImageUploading(false)
    setImageShowError(true)
  }

  const onSuccessIcon = res => {
    // console.log("resImage:", res)
    setResIcon(res)
    setImageUploading(false)
  }

  const onUploadProgress = progress => {
    if (progress) {
    }
    setImageUploading(true)
  }

  const handleServiceCategorySelectChange = selectedOptions => {
    if (selectedOptions?.length > selectedServiceCategories?.length) {
      selectedOptions.forEach(option => {
        const existingIndex = selectedServiceCategories.findIndex(
          item => item.value.toString() === option.value.toString()
        )

        if (existingIndex === -1) {
          setSelectedServiceCategories(prevState => [...prevState, option])
        } else {
          setSelectedServiceCategories(prevState => {
            const updatedVariants = [...prevState]
            updatedVariants[existingIndex] = option
            return updatedVariants
          })
        }
      })
    } else {
      setSelectedServiceCategories(selectedOptions)
    }
  }

  const handleServicesSelectChange = selectedOptions => {
    if (selectedOptions?.length > selectedServices?.length) {
      selectedOptions.forEach(option => {
        const existingIndex = selectedServices.findIndex(
          item => item.value.toString() === option.value.toString()
        )

        if (existingIndex === -1) {
          setSelectedServices(prevState => [...prevState, option])
        } else {
          setSelectedServices(prevState => {
            const updatedVariants = [...prevState]
            updatedVariants[existingIndex] = option
            return updatedVariants
          })
        }
      })
    } else {
      setSelectedServices(selectedOptions)
    }
  }

  useEffect(() => {
    setValue("fullName", data?.name)
    setValue("number", data?.number)
    setValue("email", data?.email)
    setResIcon(data?.image)
    setChecked(data?.isActive)
    setSelectedServiceCategories(
      data?.categories?.map(item => ({
        value: item._id,
        label: `${item.name.en}(${item.name.ar}) `,
      }))
    )
    setSelectedServices(
      data?.services?.map(item => ({
        value: item._id,
        label: `${item.name.en}(${item.name.ar}) `,
      }))
    )
    setStartTime(data?.startTime ? dayjs(data?.startTime, "h:mm A") : null)
    setEndTime(data?.endTime ? dayjs(data?.endTime, "h:mm A") : null)
  }, [isSuccess])

  if (isLoadingData) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  // console.log("startTime:", startTime, "endTime:", new Date(endTime))
  // console.log("data:", data)
  // console.log(
  //   "ServiceCategoryTeamMembersData",
  //   ServiceCategoryTeamMembersData?.data
  // )

  // console.log("selectedServiceCategories:", selectedServiceCategories)
  console.log("selectedServicesData:", selectedServices)

  // console.log("ServicesTeamMembersData:", ServicesTeamMembersData?.data)
  return (
    <div className="team-members-div">
      <h5 className="team-members-edit-title">
        {id ? `Edit Team Member` : `Add New Team Member`}
      </h5>{" "}
      <form>
        <div className="team-members-text-field">
          <div className="team-members-text-field-row">
            {" "}
            <TextField
              fullWidth
              label="Full Name*"
              id="fullWidth"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("fullName", { required: true })}
              error={errors.fullName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.fullName && errors.fullName?.message
                ? errors.fullName?.message.toString()
                : null}
            </Typography>
            <TextField
              fullWidth
              label="Mobile Number*"
              id="fullWidth"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("number", { required: true })}
              error={errors.number ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.number && errors.number?.message
                ? errors.number?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="team-members-text-field-row">
            <TextField
              fullWidth
              label="Email"
              id="fullWidth"
              size="small"
              InputLabelProps={{
                shrink: true,
              }}
              {...register("email", { required: true })}
              error={errors.email ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.email && errors.email?.message
                ? errors.email?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="team-members-text-field-row-time-picker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Start Time*"
                value={startTime}
                onChange={newValue => setStartTime(newValue)}
                slotProps={{ textField: { size: "small" } }}
              />
              <TimePicker
                label="End Time*"
                value={endTime}
                onChange={newValue => setEndTime(newValue)}
                slotProps={{ textField: { size: "small" } }}
              />
            </LocalizationProvider>
          </div>
          <div className="team-members-text-field-column">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label htmlFor="serviceSelect">Select Services*:</label>
              <div>
                <label>Select All</label>
                <Checkbox
                  checked={isServiceChecked}
                  onClick={() => {
                    if (isServiceChecked) {
                      // setSelectedCustomer()
                      setServiceChecked(!isServiceChecked)
                      setSelectedServices([])
                      // setEntityId()
                    } else {
                      setServiceChecked(!isServiceChecked)
                      setSelectedServices(ServicesTeamMembersData?.data)
                      // setSelectedCustomer(item?.customer)
                      // setEntityId(item?.customer?._id)
                    }
                  }}
                />
              </div>
            </div>

            <Select
              id="serviceSelect"
              options={ServicesTeamMembersData?.data || []}
              isMulti
              value={selectedServices} // Set the selected values
              onChange={handleServicesSelectChange} // Handle selection changes
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <label htmlFor="serviceCategorySelect">
                Select Service Categories(optional):
              </label>
              <div>
                <label>Select All</label>
                <Checkbox
                  checked={isServiceCategoriesChecked}
                  onClick={() => {
                    if (isServiceCategoriesChecked) {
                      // setSelectedCustomer()
                      setServiceCategoriesChecked(!isServiceCategoriesChecked)
                      setSelectedServiceCategories([])
                      // setEntityId()
                    } else {
                      setServiceCategoriesChecked(!isServiceCategoriesChecked)
                      setSelectedServiceCategories(
                        ServiceCategoryTeamMembersData?.data
                      )
                      // setSelectedCustomer(item?.customer)
                      // setEntityId(item?.customer?._id)
                    }
                  }}
                />
              </div>
            </div>
            <Select
              id="serviceCategorySelect"
              options={ServiceCategoryTeamMembersData?.data || []}
              isMulti
              value={selectedServiceCategories} // Set the selected values
              onChange={handleServiceCategorySelectChange} // Handle selection changes
            />
          </div>

          <div>
            <FormControlLabel
              control={<Switch checked={checked} onChange={handleChange} />}
              label="Is Active"
              labelPlacement="start"
            />
          </div>

          <div className="team-members-media-div">
            <div className="team-members-image-field">
              <h6 style={{ display: "flex", flexWrap: "wrap", width: "50px" }}>
                Image:
              </h6>

              {resIcon && resIcon.filePath ? (
                <div>
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <IKImage
                      urlEndpoint={urlEndpoint}
                      path={resIcon.filePath}
                      style={{
                        width: "calc(150px - 30px)",
                        height: "100px",
                        borderRadius: "10px",
                      }}
                    />
                    {resIcon.filePath && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          paddingTop: "2px",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{
                            "@media (max-width: 600px)": {
                              fontSize: "0.6rem",
                              backgroundColor: "#040D12",
                              width: "100%",
                            },
                            "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                fontSize: "0.6rem",
                                backgroundColor: "#040D12",
                                width: "100%",
                              },
                            "@media (min-width: 970px)": {
                              fontSize: "0.6rem",
                              backgroundColor: "#040D12",
                              width: "100%",
                            },
                          }}
                          onClick={() => {
                            //  setImage(null);
                            setResIcon(null)
                          }}
                          style={{ fontFamily: "outfit" }}
                        >
                          Delete
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <IKContext
                  publicKey={publicKey}
                  urlEndpoint={urlEndpoint}
                  authenticationEndpoint={authenticationEndpoint}
                >
                  {imageUploading ? (
                    <Box sx={{ display: "flex" }}>
                      <CircularProgress size={25} />
                    </Box>
                  ) : (
                    <>
                      <IKUpload
                        onError={onErrorIcon}
                        onSuccess={onSuccessIcon}
                        onUploadStart={onUploadProgress}
                        buttonProps={{ children: "+" }}
                        inputRef={inputRefMainImage}
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={e => {
                          // Check if the selected file is an image
                          const selectedFile = e.target.files[0]
                          if (selectedFile) {
                            if (!selectedFile.type.startsWith("image/")) {
                              // Check the file type and show an appropriate error message
                              if (
                                selectedFile.type.startsWith("application/pdf")
                              ) {
                                setErrorMessage(
                                  "Please select an image, not a PDF."
                                )
                              } else if (
                                selectedFile.type.startsWith("video/")
                              ) {
                                setErrorMessage(
                                  "Please select an image, not a video."
                                )
                              } else {
                                setErrorMessage("Please select an image file.")
                              }
                              // Clear the file input (optional)
                              e.target.value = null
                            } else {
                              // Clear any previous error messages
                              setErrorMessage("")
                            }
                          }
                        }}
                      />
                      <div
                        className="image-upload-btn-div"
                        onClick={() => inputRefMainImage.current.click()}
                      >
                        <FileUploadIcon />
                        <Button
                          sx={{
                            "@media (max-width: 600px)": {
                              fontSize: "0.6rem",
                            },
                            "@media (min-width: 601px) and (max-width: 960px)":
                              {
                                fontSize: "0.8rem",
                              },
                            "@media (min-width: 970px)": {
                              fontSize: "0.8rem",
                            },
                          }}
                          variant="standard"
                          // onClick={() => inputRefMainImage.current.click()}
                          style={{ fontFamily: "outfit" }}
                        >
                          Upload
                        </Button>
                      </div>
                    </>
                  )}
                </IKContext>
              )}
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </div>
          </div>
        </div>

        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
          }}
        >
          {!isLoading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => navigate(-1)}
            >
              Close
            </Button>
          ) : (
            ""
          )}
          {isLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Save
            </LoadingButton>
          ) : imageUploading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              disabled
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewTeamMember)}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  )
}

export default AddTeamMembers
