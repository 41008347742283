import React, { useState, useRef, useEffect } from "react"
import "../../css/products.css"
import {
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  FormText,
  InputGroup,
} from "react-bootstrap"
import "../../css/orders.css"
// import { Button } from "@mui/material"
import { Container, Row, Col } from "reactstrap"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {
  useCurrentProvider,
  useProvider,
  useBranches,
  usePrefixCheck,
} from "../../common/Hooks/queryCalls"
import { IKContext, IKImage, IKUpload } from "imagekitio-react"
import {
  urlEndpoint,
  publicKey,
  authenticationEndpoint,
} from "../../components/ImageKit/ImageKitCred"
import { Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { useNavigate } from "react-router-dom"
import { LottieLoading } from "../../common/LottieLoading"
import { LoadingButton } from "@mui/lab"
import { updateProvider, updateBranch } from "../../common/Services/dbServices"
import SaveIcon from "@mui/icons-material/Save"
import { Switch, FormControlLabel } from "@mui/material"
import { Button, IconButton } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

function EditDetails() {
  const [loading, setLoading] = useState(false)
  const [resIconLoading, setResIconLoading] = useState(false)
  const [resBannerLoading, setResBannerLoading] = useState(false)
  const navigate = useNavigate()
  const [resBanner, setResBanner] = useState({})
  const inputRefIcon = useRef(null)
  const inputRefBanner = useRef(null)
  const [isHoveredIcon, setIsHoveredIcon] = useState(false)
  const [isHoveredBanner, setIsHoveredBanner] = useState(false)
  const [resIcon, setResIcon] = useState({})
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [activeStatus, setActiveStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [errorMessage1, setErrorMessage1] = useState("")
  const validationSchemaEcommerce = yup.object().shape({
    englishName: yup.string().required(),
    number: yup.string().required(),
    // username: yup.string().required(),
    upiId: yup.string().required(),
    branchAddress: yup.string().required("Address is required"),
    deliveryCharge: yup
      .number()
      .typeError("Delivery Charge  must be a number")
      .required("Delivery Charge is required"),
    prefix: yup.string().required("Prefix is required"),
  })

  const validationSchemaBooking = yup.object().shape({
    englishName: yup.string().required(),
    number: yup.string().required(),
    // username: yup.string().required(),
    // upiId: yup.string().required(),
    branchAddress: yup.string().required("Address is required"),
    // deliveryCharge: yup
    //   .number()
    //   .typeError("Delivery Charge  must be a number")
    //   .required("Delivery Charge is required"),
    prefix: yup.string().required("Prefix is required"),
  })
  const currentProvider = useCurrentProvider()
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(
      currentProvider?.data?.type === "e-commerce"
        ? validationSchemaEcommerce
        : validationSchemaBooking
    ),
  })

  const { data, isLoading, refetch, isError, isSuccess } = useProvider({
    providerId: currentProvider?.data?._id,
  })
  const {
    data: branchData,
    isLoading: branchLoading,
    refetch: branchRefetch,
    isSuccess: branchIsSuccess,
  } = useBranches({
    providerId: currentProvider?.data?._id,
  })
  useEffect(() => {
    if (data) {
      setValue("englishName", data?.name?.en)
      setValue("arabicName", data?.name?.ar)
      setValue("email", data?.email)
      setValue("englishBio", data?.description?.en)
      setValue("arabicBio", data?.description?.ar)
      setValue("number", data?.number)
      setValue("whatsapp", data?.whatsapp)
      setValue("facebook", data?.facebook)
      setValue("instagram", data?.instagram)
      // setValue("username", data?.username)
      setValue("upiId", data?.upiId)
      setResIcon(data?.icon)
      setResBanner(data?.mainImage)
      setValue("branchAddress", branchData && branchData[0]?.address)
      setValue("prefix", branchData && branchData[0]?.prefix)
      setValue("deliveryCharge", branchData && branchData[0]?.deliveryCharge)
      setValue("storeAddress", branchData && branchData[0]?.storeAddress)
      setActiveStatus(branchData && branchData[0]?.inStockStatus)
    }
  }, [branchIsSuccess, isSuccess])

  const prefixCheck = usePrefixCheck({
    fieldValue: watch("prefix") || "",
    branchId: branchData?.length > 0 ? branchData[0]?._id : "",
  })

  const numberCheck = usePrefixCheck({
    fieldValue: watch("number") || "",
    branchId: branchData?.length > 0 ? branchData[0]?._id : "",
  })

  const onSubmit = async data => {
    setLoading(true)
    try {
      const result = await updateProvider({
        providerId: currentProvider?.data?._id,
        data,
        resIcon,
        resBanner,
      })
      const result1 = await updateBranch({
        providerId: currentProvider?.data?._id,
        itemId: branchData[0]?._id,
        data,
        inStockStatus: activeStatus,
      })

      // console.log("results:", result, result1)
      if (result && result1) {
        refetch()
        branchRefetch()
        setShowSuccess(true)
        setLoading(false)
        setTimeout(() => {
          setShowSuccess(false)
        }, 3000)
        navigate("/details")
      }
    } catch (error) {
      setLoading(false)
      setShowError(true)
      setTimeout(() => {
        setShowError(false)
      }, 3000)
    }
  }

  const onErrorIcon = err => {
    setResIconLoading(false)
    setShowImageError(true)
  }

  const onSuccessIcon = res => {
    setResIcon(res)
    setResIconLoading(false)
  }
  const onUploadProgressIcon = progress => {
    setResIconLoading(true)
  }

  const onUploadProgressBanner = progress => {
    setResBannerLoading(true)
  }

  const onErrorBanner = err => {
    setResBannerLoading(false)
    setShowImageError(true)
  }

  const onSuccessBanner = res => {
    setResBanner(res)
    setResBannerLoading(false)
  }

  if (isLoading) {
    return <h1>{<LottieLoading />}</h1>
  }

  if (data?.length == 0) {
    return <h1>No Items Available</h1>
  }

  if (branchLoading) {
    return (
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LottieLoading />
      </h1>
    )
  }
  return (
    <div className="product-div">
      <div className="page-title-box">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginTop: "-50px" }}
        >
          <div>
            {" "}
            <h6 className="page-title">Edit Details</h6>
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            {/* <Button
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#333547",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#333547",
                },
              }}
              variant="contained"
              onClick={() => navigate(-1)}
              style={{ fontFamily: "outfit" }}
            >
              Back
            </Button> */}
          </div>
        </div>
      </div>

      {/* <h4>Edit Details</h4>{" "} */}
      <div
        className="product-text-field"
        style={{ padding: "10px", backgroundColor: "white" }}
      >
        <div className="product-text-field-row">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className="each-textfield">
              <FormLabel>Name of your business(in English)*</FormLabel>
              <Controller
                control={control}
                name="englishName"
                render={({ field }) => <FormControl {...field} />}
              />
              {errors.englishName && <span>{errors.englishName.message}</span>}
            </FormGroup>

            <FormGroup className="each-textfield">
              <FormLabel>Short Bio about the business(in English)</FormLabel>
              <Controller
                control={control}
                name="englishBio"
                render={({ field }) => <FormControl {...field} />}
              />
              {errors.englishBio && <span>{errors.englishBio.message}</span>}
            </FormGroup>

            <FormGroup className="each-textfield">
              <FormLabel>Mobile Number*</FormLabel>
              <InputGroup>
                <InputGroup.Text>+91</InputGroup.Text>
                <Controller
                  control={control}
                  name="number"
                  render={({ field }) => <FormControl {...field} />}
                />
              </InputGroup>

              {errors.number && <span>{errors.number.message}</span>}
              {numberCheck?.data?.length > 0 && (
                <span style={{ color: "red" }}>Number already in use!</span>
              )}
            </FormGroup>
            <FormGroup className="each-textfield">
              <FormLabel>Whatsapp Number*</FormLabel>
              <InputGroup>
                <InputGroup.Text>+91</InputGroup.Text>
                <Controller
                  control={control}
                  name="whatsapp"
                  render={({ field }) => <FormControl {...field} />}
                />
              </InputGroup>

              {errors.whatsapp && <span>{errors.whatsapp.message}</span>}
            </FormGroup>
            {currentProvider?.data?.type === "e-commerce" ? (
              <FormGroup className="each-textfield">
                <FormLabel>UPI Id*</FormLabel>
                <Controller
                  control={control}
                  name="upiId"
                  render={({ field }) => <FormControl {...field} />}
                />
                {errors.upiId && <span>{errors.upiId.message}</span>}
              </FormGroup>
            ) : null}
            <FormGroup className="each-textfield">
              <FormLabel>Instagram*</FormLabel>
              <Controller
                control={control}
                name="instagram"
                render={({ field }) => <FormControl {...field} />}
              />
              {errors.instagram && <span>{errors.instagram.message}</span>}
            </FormGroup>
            <FormGroup className="each-textfield">
              <FormLabel>Facebook*</FormLabel>
              <Controller
                control={control}
                name="facebook"
                render={({ field }) => <FormControl {...field} />}
              />
              {errors.facebook && <span>{errors.facebook.message}</span>}
            </FormGroup>
            <FormGroup className="each-textfield">
              <FormLabel>Address*</FormLabel>
              <Controller
                control={control}
                name="branchAddress"
                render={({ field }) => <FormControl {...field} />}
              />
              {errors.branchAddress && (
                <span>{errors.branchAddress.message}</span>
              )}
            </FormGroup>

            <FormGroup className="each-textfield">
              <FormLabel>Prefix*</FormLabel>
              <Controller
                control={control}
                name="prefix"
                render={({ field }) => <FormControl {...field} />}
              />
              {errors.prefix && <span>{errors.prefix.message}</span>}
              {prefixCheck?.data?.length > 0 && (
                <span style={{ color: "red" }}>Prefix already taken!</span>
              )}
            </FormGroup>

            {currentProvider?.data?.type === "e-commerce" ? (
              <FormGroup className="each-textfield">
                <FormLabel>Delivery Charge*</FormLabel>
                <Controller
                  control={control}
                  name="deliveryCharge"
                  render={({ field }) => <FormControl {...field} />}
                />
                {errors.deliveryCharge && (
                  <span>{errors.deliveryCharge.message}</span>
                )}
              </FormGroup>
            ) : null}

            {currentProvider?.data?.type === "e-commerce" ? (
              <FormGroup className="each-textfield">
                <FormControlLabel
                  control={
                    <Switch
                      checked={activeStatus}
                      onClick={() => {
                        setActiveStatus(!activeStatus)
                      }}
                    />
                  }
                  label="In Store Pickup"
                />
              </FormGroup>
            ) : null}
            {currentProvider?.data?.type === "e-commerce" && activeStatus ? (
              <FormGroup className="each-textfield">
                <FormLabel>Store Location*</FormLabel>
                <Controller
                  control={control}
                  name="storeAddress"
                  render={({ field }) => (
                    <FormControl
                      {...field}
                      onClick={() => navigate(`/map/${activeStatus}`)}
                    />
                  )}
                />
                {/* <Button onClick={() => navigate(`/map/${activeStatus}`)}>
                      Set Store location
                  </Button> */}
              </FormGroup>
            ) : null}

            <div className="imageKit-div">
              <FormGroup className="each-Image-kit">
                <FormLabel>Icon </FormLabel>
                <Controller
                  control={control}
                  name="icon"
                  render={({ field }) => (
                    <div
                      // className="col-md-6"
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      {resIcon && resIcon.filePath ? (
                        <div
                          // style={{
                          //   width: 35,
                          //   height: 30,
                          //   display: "flex",
                          //   alignItems: "center",
                          //   marginTop: 10,
                          // }}
                          onMouseEnter={() => setIsHoveredIcon(true)}
                          onMouseLeave={() => setIsHoveredIcon(false)}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <IKImage
                              urlEndpoint={urlEndpoint}
                              path={resIcon.filePath}
                              width="50px"
                              height="50px"
                            />

                            <Button
                              sx={{
                                "@media (max-width: 600px)": {
                                  fontSize: "0.5rem",
                                  backgroundColor: "#333547",
                                },
                                "@media (min-width: 601px) and (max-width: 960px)":
                                  {
                                    fontSize: "0.5rem",
                                    backgroundColor: "#333547",
                                  },
                                "@media (min-width: 970px)": {
                                  fontSize: "0.5rem",
                                  backgroundColor: "#333547",
                                },
                              }}
                              variant="contained"
                              onClick={() => {
                                // setImage(null);
                                setResIcon(null)
                              }}
                              style={{ fontFamily: "outfit" }}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <IKContext
                          publicKey={publicKey}
                          urlEndpoint={urlEndpoint}
                          authenticationEndpoint={authenticationEndpoint}
                        >
                          {resIconLoading ? (
                            <Box sx={{ display: "flex" }}>
                              <CircularProgress size={25} />
                            </Box>
                          ) : (
                            <>
                              <IKUpload
                                onError={onErrorIcon}
                                onSuccess={onSuccessIcon}
                                onUploadStart={onUploadProgressIcon}
                                buttonProps={{ children: "+" }}
                                inputRef={inputRefIcon}
                                style={{ display: "none" }}
                                accept="image/*"
                                onChange={e => {
                                  // Check if the selected file is an image
                                  const selectedFile = e.target.files[0]
                                  if (selectedFile) {
                                    if (
                                      !selectedFile.type.startsWith("image/")
                                    ) {
                                      // Check the file type and show an appropriate error message
                                      if (
                                        selectedFile.type.startsWith(
                                          "application/pdf"
                                        )
                                      ) {
                                        setErrorMessage(
                                          "Please select an image, not a PDF."
                                        )
                                      } else if (
                                        selectedFile.type.startsWith("video/")
                                      ) {
                                        setErrorMessage(
                                          "Please select an image, not a video."
                                        )
                                      } else {
                                        setErrorMessage(
                                          "Please select an image file."
                                        )
                                      }
                                      // Clear the file input (optional)
                                      e.target.value = null
                                    } else {
                                      // Clear any previous error messages
                                      setErrorMessage("")
                                    }
                                  }
                                }}
                              />
                              <Button
                                variant="contained"
                                onClick={() => inputRefIcon.current.click()}
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.6rem",
                                    backgroundColor: "#333547",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.6rem",
                                      backgroundColor: "#333547",
                                    },
                                  "@media (min-width: 970px)": {
                                    fontSize: "0.6rem",
                                    backgroundColor: "#333547",
                                  },
                                }}
                              >
                                Upload
                              </Button>
                            </>
                          )}
                        </IKContext>
                      )}
                      {errorMessage && (
                        <p style={{ color: "red" }}>{errorMessage}</p>
                      )}
                    </div>
                  )}
                />
                {errors.icon && <span>{errors.icon.message}</span>}
              </FormGroup>

              <FormGroup className="each-Image-kit">
                <FormLabel> Banner </FormLabel>
                <Controller
                  control={control}
                  name="banner"
                  render={({ field }) => (
                    <div
                      // className="col-md-6"
                      style={{
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      {resBanner && resBanner.filePath ? (
                        <div
                          // style={{
                          //   width: 35,
                          //   height: 30,
                          //   display: "flex",
                          //   alignItems: "center",
                          //   marginTop: 10,
                          // }}
                          onMouseEnter={() => setIsHoveredBanner(true)}
                          onMouseLeave={() => setIsHoveredBanner(false)}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <IKImage
                              urlEndpoint={urlEndpoint}
                              path={resBanner.filePath}
                              width="50px"
                              height="50px"
                            />

                            <Button
                              sx={{
                                "@media (max-width: 600px)": {
                                  fontSize: "0.5rem",
                                  backgroundColor: "#333547",
                                },
                                "@media (min-width: 601px) and (max-width: 960px)":
                                  {
                                    fontSize: "0.5rem",
                                    backgroundColor: "#333547",
                                  },
                                "@media (min-width: 970px)": {
                                  fontSize: "0.5rem",
                                  backgroundColor: "#333547",
                                },
                              }}
                              variant="contained"
                              onClick={() => {
                                // setImage(null);
                                setResBanner(null)
                              }}
                              style={{ fontFamily: "outfit" }}
                            >
                              Delete
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <IKContext
                          publicKey={publicKey}
                          urlEndpoint={urlEndpoint}
                          authenticationEndpoint={authenticationEndpoint}
                        >
                          {resBannerLoading ? (
                            <Box sx={{ display: "flex" }}>
                              <CircularProgress size={25} />
                            </Box>
                          ) : (
                            <>
                              <IKUpload
                                onError={onErrorBanner}
                                onSuccess={onSuccessBanner}
                                onUploadStart={onUploadProgressBanner}
                                buttonProps={{ children: "+" }}
                                inputRef={inputRefBanner}
                                style={{ display: "none" }}
                                accept="image/*"
                                onChange={e => {
                                  // Check if the selected file is an image
                                  const selectedFile = e.target.files[0]
                                  if (selectedFile) {
                                    if (
                                      !selectedFile.type.startsWith("image/")
                                    ) {
                                      // Check the file type and show an appropriate error message
                                      if (
                                        selectedFile.type.startsWith(
                                          "application/pdf"
                                        )
                                      ) {
                                        setErrorMessage1(
                                          "Please select an image, not a PDF."
                                        )
                                      } else if (
                                        selectedFile.type.startsWith("video/")
                                      ) {
                                        setErrorMessage1(
                                          "Please select an image, not a video."
                                        )
                                      } else {
                                        setErrorMessage1(
                                          "Please select an image file."
                                        )
                                      }
                                      // Clear the file input (optional)
                                      e.target.value = null
                                    } else {
                                      // Clear any previous error messages
                                      setErrorMessage1("")
                                    }
                                  }
                                }}
                              />
                              <Button
                                variant="contained"
                                onClick={() => inputRefBanner.current.click()}
                                sx={{
                                  "@media (max-width: 600px)": {
                                    fontSize: "0.6rem",
                                    backgroundColor: "#333547",
                                  },
                                  "@media (min-width: 601px) and (max-width: 960px)":
                                    {
                                      fontSize: "0.6rem",
                                      backgroundColor: "#333547",
                                    },
                                  "@media (min-width: 970px)": {
                                    fontSize: "0.6rem",
                                    backgroundColor: "#333547",
                                  },
                                }}
                              >
                                Upload
                              </Button>
                            </>
                          )}
                        </IKContext>
                      )}
                      {errorMessage1 && (
                        <p style={{ color: "red" }}>{errorMessage1}</p>
                      )}
                    </div>
                  )}
                />
                {errors.banner && <span>{errors.banner.message}</span>}
              </FormGroup>
            </div>
            <div
              style={{ display: "flex", gap: "10px", justifyContent: "center" }}
            >
              {loading ? (
                <LoadingButton
                  // size="small"
                  color="secondary"
                  // onClick={handleClick}
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  <span>Save Changes</span>
                </LoadingButton>
              ) : resIconLoading || resBannerLoading ? (
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                  }}
                  variant="contained"
                  disabled
                  style={{ fontFamily: "outfit" }}
                >
                  Save Changes
                </Button>
              ) : (
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  style={{ fontFamily: "outfit" }}
                >
                  Save Changes
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default EditDetails
