import React, { useState, useEffect } from "react"
import "../../css/booking.css"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TablePagination from "@mui/material/TablePagination"
import TableRow from "@mui/material/TableRow"
import { formatCurrency } from "common/Services/functions"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import Checkbox from "@mui/material/Checkbox"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/material/styles"
import { IconButton } from "@mui/material"
import AddBoxIcon from "@mui/icons-material/AddBox"
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox"
import { Button } from "@mui/material"
import { bookingCreate } from "common/Services/dbServices"
import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import CreateCustomer from "./CreateCustomer"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import Alert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import { useNavigate } from "react-router-dom"
import { useServiceCart } from "common/Services/services"
import { FormGroup, Label, FormText } from "reactstrap"
import { DatePicker } from "reactstrap-date-picker"
import dayjs from "dayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import { useServiceMembers } from "common/Hooks/queryCalls"
import Select from "react-select"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const CreateBooking = () => {
  const navigate = useNavigate()
  const [showError, setShowError] = useState(false)
  const [showAddProductSuccess, setShowAddProductSuccess] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openProductModal, setOpenProductModal] = useState(false)
  const [openCustomerModal, setOpenCustomerModal] = useState(false)
  const [edit, setEdit] = useState(false)
  const [createdCustomer, setCreateCustomer] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(3)
  const [productSearch, setProductSearch] = useState("")
  const [customerSearch, setCustomerSearch] = useState("")
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString())
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [selectedServiceMember, setSelectedServiceMember] = useState(null)
  const [isCompleted, setCompleted] = useState(false)

  const handleChangeCompleted = event => {
    setCompleted(event.target.checked)
  }
  const {
    branchId,
    providerId,
    services,
    addToCart,
    removeFromCart,
    clearCart,
    bookingServicesData,
    bookingServicesIsLoading,
    bookingServicesRefetch,
    audience,
    isAudienceLoading,
    audienceRefetch,
    entityId,
    setEntityId,
    createCustomer,
  } = useServiceCart()

  const { data: serviceMemberData, isLoading: serviceMemberIsLoading } =
    useServiceMembers({ providerId })

  // console.log("serviceMemberData:", serviceMemberData)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }
  // console.log("bookingServicesData:", bookingServicesData)
  // console.log("cart:", cart)
  // console.log("customer:", customers)
  // console.log("selectedCustomer:", selectedCustomer)
  // console.log("selectedCustomerAddress:", selectedCustomerAddress)
  // console.log("createdCustomer:", createdCustomer)
  // console.log("audience:", audience)
  const handleCreateOrder = async () => {
    try {
      if (
        branchId &&
        selectedCustomer?._id &&
        providerId &&
        services?.items.length > 0
      ) {
        const result = await bookingCreate({
          branchId,
          customerId: selectedCustomer?._id,
          providerId,
          providerType: "booking-services",
          items: services.items,
          payments: services.totalAmount,
          serviceIds: services.items.map(service => service._id),
          startTime:
            startTime !== null ? dayjs(startTime).format("HH:mm") : null,
          estimatedEndTime:
            endTime !== null ? dayjs(endTime).format("HH:mm") : null,
          endTime:
            isCompleted === true
              ? endTime !== null
                ? dayjs(endTime).format("HH:mm")
                : null
              : null,
          bookedDate: new Date(selectedDate),
          completedOn: isCompleted === true ? new Date(selectedDate) : null,
          serviceMemberId: selectedServiceMember,
          totalDuration: services.totalDuration,
          status: isCompleted === true ? "completed" : "pending",
        })
        // console.log("created order:", result)
        setCreateCustomer([])
        setSelectedCustomer()
        setEntityId()
        audienceRefetch()
        clearCart()
        setTimeout(() => {
          setBtnLoading(false)
          navigate(`/booking-details/${result.insertedId}`)
        }, 1000)
      } else {
        const customer = await createCustomer({ data: createdCustomer[0] })
        const result = await bookingCreate({
          branchId,
          customerId: customer?.insertedId,
          providerId,
          providerType: "booking-services",
          items: services.items,
          payments: services.totalAmount,
          serviceIds: services.items.map(service => service._id),
          startTime:
            startTime !== null ? dayjs(startTime).format("HH:mm") : null,
          estimatedEndTime:
            endTime !== null ? dayjs(endTime).format("HH:mm") : null,
          endTime:
            isCompleted === true
              ? endTime !== null
                ? dayjs(endTime).format("HH:mm")
                : null
              : null,
          bookedDate: new Date(selectedDate),
          completedOn: isCompleted === true ? new Date(selectedDate) : null,
          serviceMemberId: selectedServiceMember,
          totalDuration: services.totalDuration,
          status: isCompleted === true ? "completed" : "pending",
        })
        // console.log("created order else:", result)
        setCreateCustomer([])
        setSelectedCustomer()
        setEntityId()
        audienceRefetch()
        clearCart()
        setTimeout(() => {
          setBtnLoading(false)
          navigate(`/booking-details/${result.insertedId}`)
        }, 1000)
      }
    } catch (error) {
      setBtnLoading(false)
      setShowError(true)
    }
  }
  const handleClose = () => {
    setOpen(false)
    setEdit(false)
  }
  const handleCloseProductModal = () => {
    setOpenProductModal(false)
  }
  const handleCloseCustomerModal = () => {
    setOpenCustomerModal(false)
  }
  const handleCloseToast = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setShowError(false)
    setShowAddProductSuccess(false)
  }

  // const filteredProducts = filteringProductsData?.filter(product =>
  //   product.name.en?.toLowerCase()?.includes(productSearch.toLowerCase())
  // )

  const filteredServices = bookingServicesData?.filter(product =>
    product.name.en?.toLowerCase()?.includes(productSearch.toLowerCase())
  )
  const filteredcustomers = audience?.filter(
    customer =>
      customer.customer.firstName
        ?.toLowerCase()
        ?.includes(customerSearch.toLowerCase()) ||
      customer.customer.lastName
        ?.toLowerCase()
        ?.includes(customerSearch.toLowerCase())
  )
  // console.log(filteredcustomers)

  const getCartItems = item => {
    if (services.items.length !== 0) {
      const index = services?.items?.findIndex(cartItem => {
        return cartItem._id.toString() === item._id.toString()
      })

      if (index === -1) {
        // Item is already in the cart

        return (
          <IconButton
            onClick={() => {
              setShowAddProductSuccess(true)
              addToCart(item)
            }}
          >
            <AddBoxIcon />
          </IconButton>
        )
      } else {
        // Item is not in the cart
        return (
          <IconButton
            onClick={() => {
              setShowAddProductSuccess(false)
              removeFromCart(item)
            }}
          >
            <IndeterminateCheckBoxIcon />
          </IconButton>
        )
      }
    } else {
      return (
        <IconButton
          onClick={() => {
            setShowAddProductSuccess(true)
            addToCart(item)
          }}
        >
          <AddBoxIcon />
        </IconButton>
      )
    }
  }
  // console.log("selectedDate:", typeof selectedDate)
  // console.log("isCompleted:", isCompleted)
  console.log("startTime:", dayjs(startTime).format("HH:mm"))
  return (
    <>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={3000}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="error"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Something Went Wrong!
          </Alert>
        </Snackbar>
      </Stack>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showAddProductSuccess}
          autoHideDuration={1500}
          onClose={handleCloseToast}
          key={"bottom" + "center"}
        >
          <Alert
            onClose={handleCloseToast}
            severity="success"
            sx={{ width: "100%", fontSize: "0.7rem" }}
          >
            Service added to cart
          </Alert>
        </Snackbar>
      </Stack>
      <Modal
        open={openProductModal}
        onClose={handleCloseProductModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 1,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 1,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 1,
              overflowX: "scroll",
              maxHeight: "80%",
            },
          }}
          style={{ fontFamily: "outfit" }}
        >
          <div className="services-search-div">
            <TextField
              label="Search Service Name"
              variant="outlined"
              size="small"
              onChange={newValue => {
                setProductSearch(newValue.target.value)
              }}
            />
          </div>

          {bookingServicesIsLoading || bookingServicesData === undefined ? (
            <CircularProgress sx={{ marginTop: "50px" }} />
          ) : (
            <Paper sx={{ width: "100%", overflow: "hidden", padding: 0 }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  {bookingServicesData?.length == 0 && (
                    <caption style={{ fontFamily: "outfit" }}>
                      No Items Available
                    </caption>
                  )}
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Image
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Price
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Duration
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Service Category
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredServices
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(item => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={item._id}
                            onClick={() => {
                              const index = services?.items?.findIndex(
                                cartItem => {
                                  return (
                                    cartItem._id.toString() ===
                                    item._id.toString()
                                  )
                                }
                              )

                              if (index === -1) {
                                setShowAddProductSuccess(true)
                                addToCart(item)
                              } else {
                                setShowAddProductSuccess(false)
                                removeFromCart(item)
                              }
                            }}
                          >
                            <TableCell
                              align="center"
                              sx={tableBodyStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              <img
                                style={{
                                  height: "80px",
                                  width: "100px",
                                  borderRadius: "10px",
                                }}
                                src={item?.image?.url || ""}
                              />
                            </TableCell>

                            <TableCell align="center" sx={tableBodyStyle}>
                              {item?.name?.en}
                            </TableCell>
                            <TableCell align="center" sx={tableBodyStyle}>
                              {formatCurrency(item?.salePrice || item?.price)}
                            </TableCell>
                            <TableCell align="center">
                              {`${item?.duration?.HH} hr : ${item?.duration?.MM} min`}
                            </TableCell>
                            <TableCell align="center" sx={tableBodyStyle}>
                              {item?.serviceCategory?.length > 0
                                ? item?.serviceCategory[0]?.name?.en
                                : `-`}
                            </TableCell>
                            <TableCell align="center" sx={tableBodyStyle}>
                              {getCartItems(item)}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                sx={tableFooterStyle}
                rowsPerPageOptions={[3, 5, 10, 15]}
                component="div"
                count={bookingServicesData?.length || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          <Box
            textAlign="center"
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              paddingTop: "10px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => {
                clearCart()
                setOpenProductModal(false)
              }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => handleCloseProductModal()}
            >
              Close
            </Button>
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => {
                setOpenProductModal(false)
              }}
            >
              {` Add : ${services?.items?.length || 0}`}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openCustomerModal}
        onClose={handleCloseCustomerModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 1,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 1,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 1,
              overflowX: "scroll",
              maxHeight: "80%",
            },
          }}
          style={{ fontFamily: "outfit" }}
        >
          <div className="services-search-div">
            <TextField
              label="Search Customer Name"
              variant="outlined"
              size="small"
              onChange={newValue => {
                setCustomerSearch(newValue.target.value)
              }}
            />
          </div>
          {isAudienceLoading ? (
            <CircularProgress sx={{ marginTop: "50px" }} />
          ) : (
            <Paper
              sx={{
                width: "100%",
                overflowY: "scroll",
                maxHeight: "400px",
              }}
            >
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  {audience?.length == 0 && createdCustomer.length === 0 ? (
                    <caption style={{ fontFamily: "outfit" }}>
                      No Customers Have
                    </caption>
                  ) : (
                    ""
                  )}
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Actions
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Customer Name
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Number
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={tableHeadStyle}
                        style={{ fontFamily: "outfit" }}
                      >
                        Email
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {createdCustomer?.map(item => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={item._id}
                        >
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            <Checkbox checked={true} />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >{`${item?.firstName} ${item?.lastName}`}</TableCell>
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                          >{`+91 ${item?.number}`}</TableCell>
                          <TableCell align="center" sx={tableBodyStyle}>
                            {item?.email || `-`}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    {filteredcustomers?.map(item => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={item._id}
                          onClick={() => {
                            if (
                              selectedCustomer?._id.toString() ===
                              item?.customer?._id.toString()
                            ) {
                              setSelectedCustomer()
                              setEntityId()
                            } else {
                              setSelectedCustomer(item?.customer)
                              setEntityId(item?.customer?._id)
                            }
                          }}
                        >
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >
                            <Checkbox
                              checked={
                                selectedCustomer?._id.toString() ===
                                item?.customer?._id.toString()
                              }
                              onClick={() => {
                                if (
                                  selectedCustomer?._id.toString() ===
                                  item?.customer?._id.toString()
                                ) {
                                  setSelectedCustomer()
                                  setEntityId()
                                } else {
                                  setSelectedCustomer(item?.customer)
                                  setEntityId(item?.customer?._id)
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                            style={{ fontFamily: "outfit" }}
                          >{`${item?.customer?.firstName} ${item?.customer?.lastName}`}</TableCell>
                          <TableCell
                            align="center"
                            sx={tableBodyStyle}
                          >{`+91 ${item?.customer?.number}`}</TableCell>
                          <TableCell align="center" sx={tableBodyStyle}>
                            {item?.customer?.email || `-`}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
          <Box
            textAlign="center"
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              paddingTop: "10px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => {
                setCreateCustomer([])
                setSelectedCustomer()
                setEntityId()
                setOpenCustomerModal(false)
              }}
            >
              Clear
            </Button>
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => handleCloseCustomerModal()}
            >
              Close
            </Button>
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => {
                setOpenCustomerModal(false)
              }}
            >
              {`Add`}
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            "@media (max-width: 600px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "90%",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowY: "scroll",
              maxHeight: "80%",
            },
            "@media (min-width: 970px)": {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "70%",
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              overflowX: "scroll",
              maxHeight: "80%",
            },
          }}
          style={{ fontFamily: "outfit" }}
        >
          <CreateCustomer
            providerId={providerId}
            setOpen={setOpen}
            edit={edit}
            setCreateCustomer={setCreateCustomer}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <div className="create-booking-main-div">
        <div className="page-title-box">
          <div>
            {" "}
            <h6 className="page-title">Create Bookings</h6>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Crafting New Bookings</li>
            </ol>
          </div>
        </div>
        <div className="create-booking-div">
          <div className="create-booking-content-1">
            <div className="create-booking-cart">
              <div className="create-booking-cart-btn-div">
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    setOpenProductModal(true)
                  }}
                  style={{ fontFamily: "outfit" }}
                >
                  Add Services
                </Button>
                {providerId &&
                branchId &&
                (selectedCustomer?._id || createdCustomer.length > 0) &&
                selectedDate &&
                startTime &&
                endTime &&
                services?.items.length > 0 &&
                dayjs(startTime).format("HH:mm") !== "Invalid Date" &&
                dayjs(endTime).format("HH:mm") !== "Invalid Date" ? (
                  btnLoading ? (
                    <LoadingButton
                      size="small"
                      loading
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      variant="contained"
                      sx={{
                        "@media (max-width: 600px)": {
                          fontSize: "0.6rem",
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          fontSize: "0.8rem",
                        },
                        "@media (min-width: 970px)": {
                          fontSize: "0.8rem",
                        },
                      }}
                      style={{ fontFamily: "outfit" }}
                    >
                      Create Booking
                    </LoadingButton>
                  ) : (
                    <Button
                      sx={{
                        "@media (max-width: 600px)": {
                          fontSize: "0.6rem",
                          backgroundColor: "#333547",
                        },
                        "@media (min-width: 601px) and (max-width: 960px)": {
                          fontSize: "0.8rem",
                          backgroundColor: "#333547",
                        },
                        "@media (min-width: 970px)": {
                          fontSize: "0.8rem",
                          backgroundColor: "#333547",
                        },
                      }}
                      variant="contained"
                      onClick={() => {
                        if (branchId && providerId) {
                          setBtnLoading(true)
                          handleCreateOrder()
                        } else {
                          alert("order is mot created")
                        }
                      }}
                      style={{ fontFamily: "outfit" }}
                    >
                      Create Booking
                    </Button>
                  )
                ) : (
                  <Button
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.6rem",
                        backgroundColor: "#333547",
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.8rem",
                        backgroundColor: "#333547",
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.8rem",
                        backgroundColor: "#333547",
                      },
                    }}
                    variant="contained"
                    disabled
                    style={{ fontFamily: "outfit" }}
                  >
                    Create Booking
                  </Button>
                )}
              </div>
              <h6>Services Items</h6>
              <Paper
                sx={{
                  width: "100%",
                  overflowY: "scroll",
                  maxHeight: "400px", // Hide scrollbars in WebKit (Safari and Chrome)
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },

                  // Hide scrollbars in Firefox
                  scrollbarWidth: "none",
                }}
              >
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    {services.items?.length == 0 && (
                      <caption style={{ fontFamily: "outfit" }}>
                        No Items In Service Cart
                      </caption>
                    )}
                    <TableHead>
                      <TableRow>
                        {" "}
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Images
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Price
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Duration
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={tableHeadStyle}
                          style={{ fontFamily: "outfit" }}
                        >
                          Service Category
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {services.items?.map(item => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={item._id}
                          >
                            {" "}
                            <TableCell
                              align="center"
                              sx={tableBodyStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              <img
                                style={{
                                  height: "80px",
                                  width: "100px",
                                  borderRadius: "10px",
                                }}
                                src={item?.image?.url || ""}
                              />
                            </TableCell>
                            <TableCell align="center" sx={tableBodyStyle}>
                              {item?.name?.en}
                            </TableCell>
                            <TableCell align="center">
                              {formatCurrency(item?.salePrice || item?.price)}
                            </TableCell>
                            <TableCell align="center">
                              {`${item?.duration?.HH} hr : ${item?.duration?.MM} min`}
                            </TableCell>
                            <TableCell align="center" sx={tableBodyStyle}>
                              {item?.serviceCategory?.length > 0
                                ? item?.serviceCategory[0]?.name?.en
                                : `-`}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>{" "}
              {services.items?.length > 0 ? (
                <Stack spacing={1} sx={{ width: "100%" }}>
                  <Item>
                    Total Duration:{" "}
                    {`${services.totalDuration.HH} hr : ${services.totalDuration.MM} min`}
                  </Item>
                  <Item>
                    Grand Total :{" "}
                    {formatCurrency(services.totalAmount.amountPayable || 0)}{" "}
                  </Item>
                </Stack>
              ) : (
                ""
              )}
            </div>
            <div className="create-booking-customer">
              {/* <h6>Customer List </h6> */}
              <div className="create-booking-cart-btn-div">
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    setOpenCustomerModal(true)
                  }}
                  style={{ fontFamily: "outfit" }}
                >
                  Add Customer
                </Button>
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                  }}
                  variant="contained"
                  style={{ fontFamily: "outfit" }}
                  onClick={() => {
                    setSelectedCustomer()
                    setOpen(true)
                  }}
                >
                  Create New Customer
                </Button>
              </div>
              {createdCustomer?.length > 0 || selectedCustomer ? (
                <>
                  <h6>Selected Customer</h6>
                  <Paper
                    sx={{
                      width: "100%",
                      overflowY: "scroll",
                      maxHeight: "400px",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },

                      // Hide scrollbars in Firefox
                      scrollbarWidth: "none",
                    }}
                  >
                    <TableContainer>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              sx={tableHeadStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              Name
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={tableHeadStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              Number
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={tableHeadStyle}
                              style={{ fontFamily: "outfit" }}
                            >
                              Email
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          <TableRow hover role="checkbox" tabIndex={-1}>
                            <TableCell align="center" sx={tableBodyStyle}>
                              {createdCustomer?.length > 0
                                ? ` ${createdCustomer[0]?.firstName} ${createdCustomer[0]?.lastName}`
                                : ` ${selectedCustomer?.firstName} ${selectedCustomer?.lastName}`}
                            </TableCell>
                            <TableCell align="center" sx={tableBodyStyle}>
                              {createdCustomer?.length > 0
                                ? createdCustomer[0]?.number
                                  ? ` ${createdCustomer[0]?.number}`
                                  : `-`
                                : selectedCustomer?.number
                                ? `  ${selectedCustomer?.number}`
                                : `-`}
                            </TableCell>
                            <TableCell align="center" sx={tableBodyStyle}>
                              {createdCustomer?.length > 0
                                ? createdCustomer[0]?.email
                                  ? ` ${createdCustomer[0]?.email}`
                                  : `-`
                                : selectedCustomer?.email
                                ? `  ${selectedCustomer?.email}`
                                : `-`}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </>
              ) : (
                <h6>The customer has not been chosen *</h6>
              )}

              <div className="booking-date-startTime-endTime-div">
                <FormGroup>
                  <Label>Select Booking Date*</Label>
                  <DatePicker
                    id="example-datepicker"
                    value={selectedDate}
                    onChange={(v, f) => {
                      setSelectedDate(v)
                    }}
                    dateFormat="DD/MM/YYYY"
                  />
                  <FormText>please select booking date (DD/MM/YYYY)</FormText>
                </FormGroup>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Start Time*"
                    value={startTime}
                    onChange={newValue => setStartTime(newValue)}
                    slotProps={{ textField: { size: "small" } }}
                  />
                  <TimePicker
                    label="End Time*"
                    value={endTime}
                    onChange={newValue => setEndTime(newValue)}
                    slotProps={{ textField: { size: "small" } }}
                  />
                </LocalizationProvider>
                <FormGroup>
                  <Label>Choose your team member (optional) :</Label>
                  <Select
                    options={serviceMemberData || []}
                    onChange={select => setSelectedServiceMember(select?.value)}
                  />
                  <FormText>Choose a team member from the list above.</FormText>
                </FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isCompleted}
                      onChange={handleChangeCompleted}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Is this booking already completed?"
                  labelPlacement="top"
                />
              </div>

              {providerId &&
              branchId &&
              (selectedCustomer?._id || createdCustomer.length > 0) &&
              selectedDate &&
              startTime &&
              endTime &&
              services?.items.length > 0 &&
              dayjs(startTime).format("HH:mm") !== "Invalid Date" &&
              dayjs(endTime).format("HH:mm") !== "Invalid Date" ? (
                btnLoading ? (
                  <LoadingButton
                    size="small"
                    loading
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.6rem",
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.8rem",
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.8rem",
                      },
                    }}
                    style={{ fontFamily: "outfit" }}
                  >
                    Create Booking
                  </LoadingButton>
                ) : (
                  <Button
                    sx={{
                      "@media (max-width: 600px)": {
                        fontSize: "0.6rem",
                        backgroundColor: "#333547",
                      },
                      "@media (min-width: 601px) and (max-width: 960px)": {
                        fontSize: "0.8rem",
                        backgroundColor: "#333547",
                      },
                      "@media (min-width: 970px)": {
                        fontSize: "0.8rem",
                        backgroundColor: "#333547",
                      },
                    }}
                    variant="contained"
                    onClick={() => {
                      if (branchId && providerId) {
                        setBtnLoading(true)
                        handleCreateOrder()
                      } else {
                        alert("order is mot created")
                      }
                    }}
                    style={{ fontFamily: "outfit" }}
                  >
                    Create Booking
                  </Button>
                )
              ) : (
                <Button
                  sx={{
                    "@media (max-width: 600px)": {
                      fontSize: "0.6rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 601px) and (max-width: 960px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                    "@media (min-width: 970px)": {
                      fontSize: "0.8rem",
                      backgroundColor: "#333547",
                    },
                  }}
                  variant="contained"
                  disabled
                  style={{ fontFamily: "outfit" }}
                >
                  Create Booking
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateBooking

const tableHeadStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    fontWeight: 600,
  },
}
const tableBodyStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
  },
}

const tableFooterStyle = {
  "@media (max-width: 600px)": {
    fontSize: "0.6rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.6rem",
    },
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
  "@media (min-width: 970px)": {
    fontSize: "0.8rem",
    backgroundColor: "#e9ecef",
    color: "black",
    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
      "margin-top": "1em",
      "margin-bottom": "1em",
      fontSize: "0.8rem",
    },
  },
}
